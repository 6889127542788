import React, {
  useEffect,
  useState,
  useMemo,
  Suspense,
  useCallback,
} from 'react';
import {
  Switch,
  Route,
  BrowserRouter,
  useLocation,
  Link,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createStore } from 'redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { LogoSpinnerFullCentered } from './components/LogoSpinner';
import { getStaticAssetUrl } from './helper/getStaticAssetUrl';
import { Page404 } from './pages/page404';
import rootReducer from './reducers';
import Footer from './components/Footer';
import { Page } from './helper/Theme';
import { getUserFromToken, passwordlessLogin } from './auth';
import { AuthModal } from './components/SignOn';
import {
  isBrowserSupported,
  getHomeUrl,
  isProductionServer,
  getUserId,
} from './helper';
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import { ThemeProvider } from './theme/themeProvider';
import mixpanel from 'mixpanel-browser';
import ErrorBoundary from './helper/ErrorBoundary';
import { ZyncLoadingModal } from './components/ZyncLoadingModal';
import { ZyncErrorModal } from './components/ZyncErrorModal';
import { lazy } from './helper/lazy';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';
import 'react-resizable/css/styles.css';
import 'draft-js/dist/Draft.css';
import {
  fetchSceneTemplateApi,
  fetchSeriesApi,
  fetchShowDataApi,
  fetchWorkspaceApi,
} from './helper/api';
import NewPortal from './pages/NewPortal';
import UserProfile from './pages/UserProfile/UserProfile';
import { useHotjar } from './hooks/useHotjar';
import { FeedPage } from './pages/FeedPage';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { getWorkspaceIdFromUrl } from './hooks/useTemplateCreation';
import { SoloPortal } from './pages/SoloPortal';
import { logwarn } from './helper/contextualLogger';
import { SumoSignIn } from './pages/Onboarding/AppSumo';
import { episodeTypes } from 'zync-common/types';
import { getSpeakerInviteUrl } from './pages/EventLanding/Plan/PlanInviteSpeakerCard';
import { useTrackActivity } from './hooks/track';
import noop from 'lodash/noop';

const ErrorBrowserUnsupported = getStaticAssetUrl(
  'error-browser-unsupported.svg'
);
const chromeSrc = getStaticAssetUrl('chrome.png');
const safariSrc = getStaticAssetUrl('safari.png');
const edgeSrc = getStaticAssetUrl('edge.png');
const firefoxSrc = getStaticAssetUrl('firefox.png');
const logoImage = getStaticAssetUrl('logo_text.png');

const AuthoringTool = lazy(() => import('./pages/AuthoringTool'));
const ToS = lazy(() => import('./pages/TosPrivacy'));
const ToSWebinar = lazy(() => import('./pages/ToSWebinar'));
const Meeting = lazy(() => import('./pages/Meeting'));
const Portal = lazy(() => import('./pages/Portal'));
const Series = lazy(() => import('./pages/Series'));
const SummaryPage = lazy(() => import('./components/Summary'));
const TemplateGallery = lazy(() => import('./pages/TemplateGallery'));
const WorkspaceDetails = lazy(() =>
  import('./pages/Workspaces/WorkspaceDetails')
);
const Welcome = lazy(() => import('./pages/Welcome'));
const SoloWelcome = lazy(() => import('./pages/SoloWelcome'));
const ZyncRegistration = lazy(() => import('./pages/ZyncRegistration'));
const EventLandingPage = lazy(() =>
  import('./pages/EventLanding/EventLandingPage')
);
const RSVPPage = lazy(() => import('./pages/RSVPPage'));
const SharedHighlightPage = lazy(() => import('./pages/SharedHighlightPage'));
const QuestionPage = lazy(() => import('./pages/QuestionPage'));
const SharedPublishPage = lazy(() => import('./pages/SharedPublishPage'));
const ImageGenerationPage = lazy(() => import('./pages/ImageGenerationPage'));
const ImageGenerationGeneric = lazy(() =>
  import('./pages/ImageGenerationGeneric')
);
const RegisterThankYouPage = lazy(() => import('./pages/RegisterThankYouPage'));
const PublicMeetingPage = lazy(() =>
  import('./pages/Series/PublicMeetingPage')
);
const GuestQuestionsPage = lazy(() => import('./pages/Series/GuestQuestions'));
const SpeakersPage = lazy(() => import('./pages/SpeakersPage'));
const EpisodePage = lazy(() => import('./pages/EpisodePage/EpisodePage'));
const PodcastPage = lazy(() => import('./pages/PodcastPage/PodcastPage'));
const UploadLocalRecordingPage = lazy(() =>
  import('./pages/UploadLocalRecordingPage')
);
const ShowPage = lazy(() => import('./pages/ShowPage/ShowPage'));
const SpeakerKitPage = lazy(() =>
  import('./pages/SpeakerKitPage/SpeakerKitPage')
);
const OAuthPage = lazy(() => import('./pages/OAuth'));
const SpeakerExitPage = lazy(() => import('./pages/SpeakerExitPage'));
const TestingPage = lazy(() => import('./pages/TestingPage/TestingPage'));
const FeedPageSolo = lazy(() => import('./pages/FeedPage/FeedPageSolo'));
const VideoClipPage = lazy(() => import('./pages/VideoClipPage'));

// Proxy events. See: https://developer.mixpanel.com/docs/collection-via-a-proxy
const baseConfig = {
  api_host: 'https://relay.zync.ai',
  ignore_dnt: true,
  track_pageview: 'url-with-path-and-query-string',
};

if (/^(app\.)?zync\.ai/.test(window.location.hostname.toLowerCase())) {
  // zync.ai, app.zync.ai: Use production key.
  mixpanel.init('ff896cb06c3156a8fe9b7293775925c8', { ...baseConfig });
} else {
  // Everything else (test.zync.ai, localhost): Use development key.
  mixpanel.init('599e282f308c71a53a6257897a7bcd4a', {
    ...baseConfig,
    debug: true,
  });
}

log.setLevel('info');
const customJSON = (log) => {
  const messageJSON = JSON.parse(log.message || '{}');
  return {
    userId: messageJSON.userId,
    meetingSeriesId: messageJSON.meetingSeriesId,
    meetingId: messageJSON.meetingId,
    message: messageJSON.message,
    level: log.level.label,
    stacktrace: messageJSON.stacktrace,
  };
};

const isProduction = isProductionServer();

remote.apply(log, {
  format: customJSON,
  url: '/api/logger',
  interval: 60_000,
  stacktrace: {
    levels: ['info', 'trace', 'warn', 'error'],
    depth: 20,
    excess: 0,
  },
});

log.enableAll();

library.add(fas);

export const WorkspaceContext = React.createContext({
  setContextWorkspace: () => {},
  workspace: { workspaceId: null },
  refreshWorkspace: noop,
  workspacePlanCache: {},
  isLoading: false,
});

const WorkspaceProvider = ({ children }) => {
  const [workspace, setWorkspace] = useState({
    workspacePlanCache: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPageData, setShowPageData] = useState({});
  const [allTemplates, setAllTemplates] = useState(new Map());
  const { workspaceId } = useSelector((_st) => _st.auth, shallowEqual);
  const track = useTrackActivity();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      track('Window Event - Page Unloaded');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [track]);

  useEffect(() => {
    const wsId = workspaceId || getWorkspaceIdFromUrl();

    if (!wsId) {
      return;
    }

    const allTemplates = new Map();

    const keys = [
      'scene_poster_interview_1x0',
      'scene_poster_interview_1x1',
      'scene_poster_interview_1x2',
      'scene_poster_interview_1x3',
      'scene_poster_interview_1x4',
      'scene_poster_interview_1x5',
      'scene_poster_interview_1x6',
    ];

    Promise.all(keys.map((key) => fetchSceneTemplateApi(key))).then(
      (results) => {
        results.forEach((result, i) => allTemplates.set(keys[i], result));

        setAllTemplates(allTemplates);
      }
    );
  }, [workspaceId]);

  const setContextWorkspace = useCallback((updatedWorkspace) => {
    setWorkspace(updatedWorkspace);
  }, []);

  const fetchWorkspaceInBackground = useCallback(async () => {
    const wsId = workspaceId || getWorkspaceIdFromUrl();

    if (!wsId) {
      return;
    }

    const json = await fetchWorkspaceApi(wsId);

    const { result } = json;

    setWorkspace(result);
  }, [workspaceId]);

  useEffect(() => {
    const initializeData = async () => {
      const wsId = workspaceId || getWorkspaceIdFromUrl();

      if (!wsId) {
        return;
      }

      setIsLoading(true);

      const json = await fetchWorkspaceApi(wsId);
      const showDataApiResult = await fetchShowDataApi(wsId);

      const { result } = json;

      setIsLoading(false);
      setWorkspace(result);
      setShowPageData(showDataApiResult?.showPageData);
    };

    initializeData();
  }, [workspaceId]);

  return (
    <WorkspaceContext.Provider
      value={{
        workspace,
        workspacePlanCache: workspace?.workspacePlanCache || {},
        showPageData,
        allTemplates,
        setContextWorkspace,
        isLoading,
        refreshWorkspace: fetchWorkspaceInBackground,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

function App() {
  const store = createStore(rootReducer);
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider>
          <BrowserRouter>
            <ErrorBoundary>
              <WorkspaceProvider>
                <Main />
              </WorkspaceProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
}

const Main = () => {
  const userId = useSelector((_st) => _st.auth.user?.userId);
  const authenticated = useSelector((_st) => _st.auth.authenticated);
  const token = useSelector((_st) => _st.auth.token);

  const dispatch = useDispatch();

  useHotjar();

  // Crisp chat setup
  useEffect(() => {
    if (!isProduction) {
      return;
    }
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'a1763247-dd3b-4fec-9037-a0e903a867a0';
    // Hide crisp chat native icon and use productfruits lifering instead
    window.CRISP_READY_TRIGGER = function () {
      window.$crisp.push(['do', 'chat:hide']);
    };
    (function () {
      var d = document;
      var s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }, []);

  useEffect(() => {
    const fetchUserAndDispatch = async (token) => {
      const user = await getUserFromToken(token);
      if (!user) {
        return null;
      } else {
        dispatch({ type: 'LOGIN_SUCCESS', user, token });
        return user;
      }
    };
    let retryInterval;
    if (authenticated && !userId) {
      fetchUserAndDispatch(token).then((result) => {
        if (!result) {
          // Retry every 12 seconds if the initial request is not successful.
          retryInterval = setInterval(() => {
            fetchUserAndDispatch(token);
          }, 12 * 1000);
        }
      });
    }
    return () => {
      retryInterval && clearInterval(retryInterval);
    };
  }, [userId, authenticated, token, dispatch]);

  return (
    <>
      <Suspense
        fallback={
          <div className="w-screen h-screen grid place-content-center">
            <LogoSpinnerFullCentered />
          </div>
        }
      >
        <Switch>
          <ProtectedRoute
            exact
            path={['/series/:meetingSeriesId/live', '/e/:meetingSeriesId/live']}
            component={Meeting}
            allowGuestAccess={true}
          />
          <ProtectedRoute
            exact
            path="/templates"
            component={TemplateGallery}
            allowGuestAccess={true}
          />
          <ProtectedRoute path="/linkedin" component={LinkedInCallback} />
          <ProtectedRoute path="/profile" component={UserProfile} />
          <ProtectedRoute path="/workspace" component={WorkspaceDetails} />
          <ProtectedRoute exact path={['/']} component={NewPortal} />
          <ProtectedRoute exact path="/portal" component={NewPortal} />
          <ProtectedRoute exact path="/solo" component={SoloPortal} />
          <ProtectedRoute exact path="/gallery" component={Portal} />
          <ProtectedRoute exact path="/speakers" component={SpeakersPage} />
          <ProtectedRoute
            exact
            path={[
              '/series/:meetingSeriesId/designer',
              '/e/:meetingSeriesId/designer',
            ]}
            component={AuthoringTool}
          />
          <Route
            path={['/:workspaceId/feed/:highlightId', '/:workspaceId/feed']}
            component={FeedPage}
          />
          <Route
            path={[
              '/:workspaceId/videofeed/:highlightId',
              '/:workspaceId/videofeed',
            ]}
            component={FeedPageSolo}
          />
          <Route path="/:meetingSeriesId/episode" component={EpisodePage} />
          <Route path="/:meetingSeriesId/podcast" component={PodcastPage} />
          <Route
            path={[
              '/:meetingSeriesId/local-recording/upload',
              '/e/:meetingSeriesId/local-recording/upload',
            ]}
            component={UploadLocalRecordingPage}
          />
          <Route path="/:workspaceId/show" component={ShowPage} />
          <Route
            exact
            path={[
              '/:meetingSeriesId/speaker/:speaker',
              '/:meetingSeriesId/speaker/:speaker/:subpage',
            ]}
            component={SpeakerKitPage}
          />
          <Route
            exact
            path={[
              '/series/:meetingSeriesId/register',
              '/e/:meetingSeriesId/register',
            ]}
            component={RSVPPage}
          />
          <Route
            exact
            path={[
              '/series/:meetingSeriesId/question',
              '/e/:meetingSeriesId/question',
            ]}
            component={QuestionPage}
          />
          <Route
            exact
            path={[
              '/series/:meetingSeriesId/olddetails',
              '/e/:meetingSeriesId/olddetails',
            ]}
            component={Series}
          />
          <ProtectedRoute
            exact
            path={[
              '/series/:meetingSeriesId/highlights',
              '/e/:meetingSeriesId/highlights',
            ]}
            component={SummaryPage}
          />
          <Route
            exact
            path={[
              '/series/:meetingSeriesId/highlights/:meetingId',
              '/e/:meetingSeriesId/highlights/:meetingId',
            ]}
            component={SummaryPage}
          />
          <ProtectedRoute
            exact
            path="/e/:meetingSeriesId/guestquestions"
            component={GuestQuestionsPage}
          />
          <PageRoute exact path="/tos" component={ToS} />
          <PageRoute exact path="/toswebinar" component={ToSWebinar} />
          <ProtectedRoute exact path="/welcome" component={Welcome} />
          <ProtectedRoute
            exact
            path="/solostudiowelcome"
            component={SoloWelcome}
          />
          <ProtectedRoute
            exact
            path={[
              '/e/:meetingSeriesId/details',
              '/e/:meetingSeriesId/details/:tab',
              '/e/:meetingSeriesId/details/:tab/:subpage',
              '/e/:meetingSeriesId/details/:tab/:subpage/:section',
            ]}
            component={EventLandingPage}
          />
          <ProtectedRoute exact path="/oauth" component={OAuthPage} />
          <ProtectedRoute
            exact
            path="/clip/:clipId"
            component={VideoClipPage}
          />
          <Route
            exact
            path={[
              '/e/:meetingSeriesId/contentkit',
              '/e/:meetingSeriesId/contentkit/:subpage',
              '/e/:meetingSeriesId/contentkit/:subpage/:section',
            ]}
            component={SharedPublishPage}
          />
          <Route
            exact
            path={[
              '/imageGeneration/:meetingSeriesId',
              '/imageGeneration/:meetingSeriesId/:meetingId/:highlightId',
            ]}
            component={ImageGenerationPage}
          />
          <Route
            exact
            path={['/imageGenerationGeneric']}
            component={ImageGenerationGeneric}
          />
          <Route
            exact
            path="/highlight/:meetingId/:highlightId/:socialMediaType"
            component={SharedHighlightPage}
          />
          <Route
            exact
            path={[
              '/e/:meetingSeriesId/join',
              '/e/:meetingSeriesId',
              '/series/:meetingSeriesId',
            ]}
            component={PublicMeetingPage}
          />
          <Route
            exact
            path="/e/:meetingSeriesId/speakerExit"
            component={SpeakerExitPage}
          />

          <Route exact path="/thank-you" component={RegisterThankYouPage} />
          <Route exact path="/register" component={ZyncRegistration} />
          <Route exact path="/testing" component={TestingPage} />
          <Route exact path="/onboarding/app-sumo" component={SumoSignIn} />
          <Route component={Page404} />
        </Switch>
      </Suspense>
    </>
  );
};

const getAuthMode = (emailParam, requestIntent = '') => {
  if (emailParam) {
    return 'resend';
  }

  if (requestIntent === 'register') {
    return 'register';
  }

  return 'signin';
};

const ProtectedRoute = ({
  component: Component,
  allowGuestAccess,
  ...rest
}) => {
  const dispatch = useDispatch();

  /**
   * Handle user creation in redux if the following query parameters are true and non-empty respectively:
   * allowAccessWithoutSignOn
   * userName
   */
  const { search } = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const allowAccessWithoutSignOn =
    urlSearchParams.get('allowAccessWithoutSignOn') === 'true';
  const userName = urlSearchParams.get('userName') ?? 'You';

  useEffect(() => {
    if (userName && allowAccessWithoutSignOn) {
      const userId = getUserId();
      dispatch({ type: 'SET_USER', user: { userId, userName } });
    }
  }, [dispatch, userName, urlSearchParams, allowAccessWithoutSignOn]);

  const { authenticated, user } = useSelector((_st) => _st.auth);
  const [browserSupported, setBrowserSupported] = useState('unknown');
  const { meetingSeriesId, templateKey } = (rest.computedMatch || {}).params;
  const queryParams = new URLSearchParams(useLocation().search);
  const [series, setSeries] = useState();

  const {
    location: { pathname },
  } = useHistory();

  useEffect(() => {
    if (!meetingSeriesId) return;
    const fetchSeries = async () => {
      const result = await fetchSeriesApi(meetingSeriesId);
      setSeries(result);
      if (result.upcomingMeeting) {
        dispatch({
          type: 'REFRESH_MEETING_STATE',
          meetingState: result.upcomingMeeting,
          reason: 'Loaded meeting series',
        });
      }
    };

    fetchSeries();

    return () => {
      setSeries(null);
    };
  }, [meetingSeriesId, dispatch, pathname, user?.userId]);

  // Access code based authentication (either from slack or from zync generated secure link)
  const userIdFromSecureLink = queryParams.get('userId');

  // Zync generated accessCode
  const zyncAccessCode = queryParams.get('accessCode');

  // Capture the intent used. Intent can be one of 'register' and 'signin'.
  const requestIntent = queryParams.get('intent');

  const emailParam = queryParams.get('email');

  const dispatchLoginFailure = useCallback(
    () =>
      dispatch({
        type: 'LOGIN_FAILURE',
        error:
          'Unable to validate your secured link access credentials. Login with your zync credentials.',
      }),
    [dispatch]
  );

  useEffect(() => {
    if (!isBrowserSupported()) {
      logwarn({
        message: `User is on an unsupported browser: ${navigator.userAgent.toLowerCase()}`,
        userId: user?.userId,
      });
      setBrowserSupported('unsupported');
    } else {
      setBrowserSupported('supported');
    }
  }, [user?.userId]);

  useEffect(() => {
    const verifyZyncAccessCodeAndPerformLogin = async (
      zyncAccessCode,
      userId,
      meetingSeriesId
    ) => {
      try {
        const user = await passwordlessLogin({
          userId,
          accessCode: zyncAccessCode,
          meetingSeriesId,
        });
        if (user?.userId) {
          dispatch({ type: 'LOGIN_SUCCESS', user });
        } else {
          dispatchLoginFailure();
        }
      } catch (err) {
        dispatchLoginFailure();
      }
    };

    if (authenticated) return;
    if (!userIdFromSecureLink) return;
    if (zyncAccessCode) {
      // zyncAccessCode is set
      if (!meetingSeriesId) return;
      verifyZyncAccessCodeAndPerformLogin(
        zyncAccessCode,
        userIdFromSecureLink,
        meetingSeriesId
      );
    }
  }, [
    zyncAccessCode,
    userIdFromSecureLink,
    authenticated,
    meetingSeriesId,
    dispatch,
    dispatchLoginFailure,
  ]);

  if (
    browserSupported === 'unknown' ||
    ((rest.path.includes('/series/:meetingSeriesId/live') ||
      rest.path.includes('/e/:meetingSeriesId/live')) &&
      !series)
  ) {
    return <ZyncLoadingModal message={'Powering up'} series={series} />;
  }

  if (browserSupported === 'unsupported') {
    return (
      <ZyncErrorModal
        title="Browser not supported"
        message="We do not support your current browser. Please download any one of the following browsers to attend the meeting."
        imgSrc={ErrorBrowserUnsupported}
      >
        <div className="flex flex-row gap-4">
          <BrowserDownloadLink
            imgSrc={safariSrc}
            browserDownloadLink="https://support.apple.com/downloads/safari"
          />
          <BrowserDownloadLink
            imgSrc={chromeSrc}
            browserDownloadLink="https://www.google.com/chrome/"
          />
          <BrowserDownloadLink
            imgSrc={edgeSrc}
            browserDownloadLink="https://www.microsoft.com/en-us/edge"
          />
          <BrowserDownloadLink
            imgSrc={firefoxSrc}
            browserDownloadLink="https://www.mozilla.org/en-US/firefox/new/"
          />
        </div>
      </ZyncErrorModal>
    );
  }

  if (!user) {
    if (authenticated) {
      return <ZyncLoadingModal message={'Powering up'} series={series} />;
    } else {
      // Only allow joining as guest if there was not an intent forcing the user to register or login.
      if (!!allowGuestAccess && !requestIntent) {
        if (
          (rest.path.includes('/series/:meetingSeriesId/live') ||
            rest.path.includes('/e/:meetingSeriesId/live')) &&
          !zyncAccessCode
        ) {
          if (series?.settings?.episodeType === episodeTypes.solo) {
            const inviteUrl = getSpeakerInviteUrl(
              series.settings.eventPresenters[0]?.speaker ||
                series.settings.eventPresenters[0],
              meetingSeriesId,
              true
            );

            return <Redirect to={inviteUrl} />;
          }

          return <Redirect to={`/e/${meetingSeriesId}`} />;
        }
        return (
          <AuthModal
            authMode="joinasguest"
            allowJoinAsGuest={true}
            closeAuth={() => {
              window.location.href = getHomeUrl();
            }}
          />
        );
      } else {
        return (
          <AuthModal
            authMode={getAuthMode(emailParam, requestIntent)}
            allowJoinAsGuest={false}
            closeAuth={() => {
              window.location.href = getHomeUrl();
            }}
          />
        );
      }
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <ErrorBoundary userId={user?.userId} meetingSeriesId={meetingSeriesId}>
          <Component
            {...props}
            user={user}
            templateKey={templateKey}
            series={series}
            setSeries={setSeries}
          />
        </ErrorBoundary>
      )}
    />
  );
};

const PageRoute = ({ simpleHeader, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Page>
          <div style={{ maxWidth: '1080px', margin: '0 auto' }}>
            <div
              style={{
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Link to="/">
                <img
                  style={{
                    height: '40px',
                    float: 'left',
                    display: 'inline-block',
                  }}
                  src={logoImage}
                  alt="icon"
                />
              </Link>
            </div>
            <Component {...props} />
          </div>
          <Footer />
        </Page>
      )}
    />
  );
};

const BrowserDownloadLink = ({ imgSrc, browserDownloadLink }) => {
  return (
    <a
      href={browserDownloadLink}
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer"
    >
      <img src={imgSrc} alt="" className="w-14" />
    </a>
  );
};

export default App;
