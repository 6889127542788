import { LogoSpinnerFullCentered } from '../../components/LogoSpinner';
import { FuturisticBackground } from '../../components/FuturisticBackground';
import classNames from '../../helper/classNames';
import { Modal, ModalBody, ModalWindow } from '../../components/Modal';
import { useCreateNewEvent as fnCreateNewEvent } from './useCreateNewEvent';
import { useCreateNewSoloInterview as fnCreateNewSoloInterview } from './useCreateNewSoloInterview';
import { useCreateNewBlogBasedSoloInterview as fnCreateNewBlogBasedSoloInterview } from './useCreateNewBlogBasedSoloInterview';
import { useCreateNewProfileBasedSoloInterview as fnCreateProfileBasedSoloInterview } from './useCreateNewProfileBasedSoloInterview';
import { useCreateNewIdeaBasedSoloInterview as fnCreateNewIdeaBasedSoloInterview } from './useCreateNewIdeaBasedSoloInterview';
import {
  ADD_HOST,
  ADD_SPEAKER,
  MEETING_TOPIC,
  ProgressStates,
  SET_BLOG_POST,
  SET_DATE,
  SET_DISCUSSION_PTS,
  SET_IDEA,
} from './constants';
import { MeetingTopic } from './MeetingTopic';
import { SetDate } from './SetDate';
import {
  emptyScheduledEvent,
  getScheduledEvent,
  useMeetingCreator,
} from './useMeetingCreator';
import { AddQuestions } from './AddQuestions';
import { useInterviewMeetingTemplate } from './useInterviewMeetingTemplate';
import { usePosterSlide } from '../../components/series/settings/usePosterSlide';
import { ScenePreview } from '../../components/authoring/Menu/ScenePreview';
import { AddHost } from './AddHost';
import { getEventTopicPrompt } from 'zync-common/helper/promptBuilders';
import { SelectSpeakerDropdown } from './SelectSpeakerDropdown';
import { ToastOutlet } from '../../components/ToastOutlet';
import { isMobile } from '../../helper';
import { StageArrows } from '../../components/onboarding/StageArrows';
import { ProgressBar } from '../../components/onboarding/ProgressBar';
import { TaskCardHeader } from '../EventLanding/Plan/Plan';
import { episodeTypes } from 'zync-common/types';
import React, { useEffect } from 'react';
import { AddBlogPost } from './AddBlogPost';
import { isEqual } from 'lodash';
import { AddIdea } from './AddIdea';

export const getSlideTemplateKey = (eventPresentersCount) => {
  const countToTemplateKeyMap = {
    0: 'scene_poster_interview_1x0',
    1: 'scene_poster_interview_1x1',
    2: 'scene_poster_interview_1x2',
    3: 'scene_poster_interview_1x3',
    4: 'scene_poster_interview_1x4',
    5: 'scene_poster_interview_1x5',
    6: 'scene_poster_interview_1x6',
  };

  return countToTemplateKeyMap[eventPresentersCount];
};
export const CreateNewEventModal = ({
  onClose,
  workspaceId,
  workspacePlan,
  defaultHostId,
  usePreview = true,
  episodeType = episodeTypes.default,
}) => {
  const { template } = useInterviewMeetingTemplate();

  const {
    title,
    setTitle,
    newMeetingSeriesId,
    eventHost,
    setEventHost,
    eventPresenters,
    temporaryEventPresenter,
    setTemporaryEventPresenter,
    setEventPresenters,
    scheduledEvent,
    setScheduledEvent,
    questions,
    setQuestions,
    createNewEvent,
    isCreatingWithDate,
    setIsCreatingWithDate,
    whenDateWasSetForTheFirstTime,
    setWhenDateWasSetForTheFirstTime,
    isAddingSpeaker,
    setIsAddingSpeaker,
    isMissingPresenterError,
    visualProgress,
  } = useMeetingCreator({
    workspaceId,
    workspacePlan,
    defaultHostId,
    episodeType,
    onClose,
  });

  const presenters = isAddingSpeaker
    ? [eventHost, ...eventPresenters, temporaryEventPresenter]
    : [eventHost, ...eventPresenters];

  const previewTemplateKey = getSlideTemplateKey(
    eventPresenters.length + (isAddingSpeaker ? 1 : 0)
  );

  const { posterSlide, posterSlideWithPlaceholderValues } = usePosterSlide(
    {
      ...template,
      settings: template
        ? {
            ...template.settings,
            eventPresenters: presenters,
          }
        : {
            eventPresenters: [],
          },
      scheduledEvent: isCreatingWithDate
        ? getScheduledEvent(scheduledEvent)
        : getScheduledEvent(emptyScheduledEvent),
      title,
    },
    undefined,
    false,
    previewTemplateKey
  );

  const useCreateNewEvent = {
    [episodeTypes.default]: fnCreateNewEvent,
    [episodeTypes.solo]: fnCreateNewSoloInterview,
    [episodeTypes.soloBlogBased]: fnCreateNewBlogBasedSoloInterview,
    [episodeTypes.soloIdeaBased]: fnCreateNewIdeaBasedSoloInterview,
    [episodeTypes.soloProfileBased]: fnCreateProfileBasedSoloInterview,
  };

  const {
    checkpoint,
    checkpointsData,
    isLoading,
    getProgressState,
    handleNextClick,
    handlePreviousClick,
    previousButtonStyling,
    nextButtonStyling,
    brandKit,
    styleToEnablePreviousButtonAndNextButton,
    styleToDisablePreviousButtonAndDisableNextButton,
    areDatesValid,
    newBlogPost,
    setNewBlogPost,
  } = useCreateNewEvent[episodeType]({
    createNewEvent,
    scheduledEvent: getScheduledEvent(scheduledEvent),
    title,
    eventSpeakersCount: eventPresenters.length,
  });

  const eventSpeakersCount = eventPresenters.length;
  useEffect(() => {
    if (
      (episodeType === episodeTypes.solo ||
        episodeType === episodeTypes.soloBlogBased ||
        episodeType === episodeTypes.soloProfileBased) &&
      eventSpeakersCount === 0
    ) {
      styleToDisablePreviousButtonAndDisableNextButton();
    }

    if (episodeType === episodeTypes.soloBlogBased) {
      if (newBlogPost.length >= 100) {
        styleToEnablePreviousButtonAndNextButton();
      } else {
        styleToDisablePreviousButtonAndDisableNextButton();
      }
    }

    if (episodeType === episodeTypes.soloIdeaBased) {
      if (newBlogPost.length >= 15) {
        styleToEnablePreviousButtonAndNextButton();
      } else {
        styleToDisablePreviousButtonAndDisableNextButton();
      }
    }
  }, [
    newBlogPost,
    episodeType,
    eventSpeakersCount,
    styleToEnablePreviousButtonAndNextButton,
    styleToDisablePreviousButtonAndDisableNextButton,
  ]);

  const previewComponent =
    usePreview && posterSlideWithPlaceholderValues ? (
      <div
        className={classNames(
          'flex-shrink-0',
          eventHost?.emailAddress ? '' : 'invisible'
        )}
        key={eventPresenters[0]?.presenterPictureUrl}
      >
        <ScenePreview
          key={previewTemplateKey}
          scene={posterSlide}
          brandKit={brandKit}
          width={432}
          height={(432 / 16) * 9}
        />
      </div>
    ) : null;

  if (isLoading) {
    return (
      <div className="fixed inset-0 w-full h-full grid place-content-center bg-white">
        <div className="fixed left-[-9999px] top-[-9999px]">
          <ScenePreview
            scene={posterSlide}
            brandKit={brandKit}
            htmlId="poster-id"
            height={900}
            width={1600}
          />
        </div>
        <LogoSpinnerFullCentered />
        {visualProgress ? (
          <div className="font-medium text-sm text-blue-gray text-center">
            {visualProgress}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <ModalWindow size={Modal.size.fullscreen} withBackgroundBlur>
      <ModalBody>
        <div className="w-screen md:h-screen">
          <FuturisticBackground />
          <div className="z-50 flex flex-col absolute w-[calc(100%-52px)] mx-auto md:min-w-[800px] md:max-w-[1100px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-4 md:px-0 shadow-800 rounded-xl">
            <button
              className="absolute cursor-pointer p-0 border-0 top-2 right-4 w-min h-min text-2xl"
              onClick={() => {
                onClose();
              }}
            >
              ×
            </button>
            <div className="bg-white flex flex-col justify-center items-center min-h-[52px] my-6 md:my-8 space-y-3 md:space-y-0">
              <span className="text-base md:text-xl font-semibold md:font-semibold text-blue-dark text-center md:text-left">
                {checkpoint?.topics[0]}
              </span>
              {checkpoint?.topics[1] && (
                <span className="text-xs md:text-md font-medium md:font-bold text-blue-gray md:text-light-gray text-center md:text-left">
                  {checkpoint?.topics[1]}
                </span>
              )}
            </div>
            <div className="bg-white flex justify-between items-center md:px-3 space-x-2">
              <div
                className={`mx-auto flex bg-purple bg-opacity-[5%] grow ${
                  episodeType === episodeTypes.solo
                    ? ''
                    : 'min-h-[153px] md:min-h-[250px]'
                } rounded-md items-center max-w-[85%] p-6 justify-between gap-6`}
              >
                {template && checkpoint === MEETING_TOPIC && (
                  <MeetingTopic
                    title={title}
                    setTitle={setTitle}
                    episodeType={episodeType}
                  />
                )}
                {template && checkpoint === ADD_HOST && (
                  <AddHost
                    workspaceId={workspaceId}
                    setEventHost={setEventHost}
                    defaultHostId={defaultHostId}
                  />
                )}
                {template && checkpoint === ADD_SPEAKER && (
                  <div className="w-full self-start">
                    <TaskCardHeader className="mb-4">
                      Do you want to add speakers to your episode?
                    </TaskCardHeader>
                    <SelectSpeakerDropdown
                      temporaryEventPresenter={temporaryEventPresenter}
                      setTemporaryEventPresenter={setTemporaryEventPresenter}
                      eventPresenters={eventPresenters}
                      setEventPresenters={setEventPresenters}
                      newMeetingSeriesId={newMeetingSeriesId}
                      onInvalidSpeakerAdd={
                        styleToDisablePreviousButtonAndDisableNextButton
                      }
                      onValidSpeakerAdd={
                        episodeType === episodeTypes.solo &&
                        eventPresenters.length === 0
                          ? undefined
                          : styleToEnablePreviousButtonAndNextButton
                      }
                      isAddingSpeaker={isAddingSpeaker}
                      setIsAddingSpeaker={setIsAddingSpeaker}
                      workspaceId={workspaceId}
                      isCreatingEpisode={true}
                    />
                  </div>
                )}
                {template && checkpoint === SET_DATE && (
                  <SetDate
                    scheduledEvent={scheduledEvent}
                    setScheduledEvent={setScheduledEvent}
                    isCreatingWithDate={isCreatingWithDate}
                    setIsCreatingWithDate={setIsCreatingWithDate}
                    setWhenDateWasSetForTheFirstTime={
                      setWhenDateWasSetForTheFirstTime
                    }
                    whenDateWasSetForTheFirstTime={
                      whenDateWasSetForTheFirstTime
                    }
                    areDatesValid={areDatesValid}
                  />
                )}
                {template && checkpoint === SET_DISCUSSION_PTS && (
                  <AddQuestions
                    questions={questions}
                    setQuestions={setQuestions}
                    showSuggestQuestion={episodeType !== episodeTypes.solo}
                    episodeType={episodeType}
                    suggestQuestionPrompt={getEventTopicPrompt({
                      ...template,
                      settings: template
                        ? {
                            ...template.settings,
                            eventPresenters: presenters,
                          }
                        : {
                            eventPresenters: [],
                          },
                      scheduledEvent: isCreatingWithDate
                        ? getScheduledEvent(scheduledEvent)
                        : getScheduledEvent(emptyScheduledEvent),
                      title,
                    })}
                  />
                )}
                {template && checkpoint === SET_BLOG_POST && (
                  <AddBlogPost
                    newBlogPost={newBlogPost}
                    setNewBlogPost={setNewBlogPost}
                  />
                )}
                {template && checkpoint === SET_IDEA && (
                  <AddIdea
                    newBlogPost={newBlogPost}
                    setNewBlogPost={setNewBlogPost}
                  />
                )}
                {previewComponent}
              </div>
            </div>
            {isMissingPresenterError ? (
              <div className="mx-auto w-1/2 text-sm font-medium text-center p-2 mt-4 text-orange-light bg-orange-light/20 border border-orange-light rounded-md">
                No default host set. Please got to Solo Studio Settings and set
                default host
              </div>
            ) : null}
            <div className="bg-white h-20 flex space-x-1 md:space-x-8 justify-center items-center text-light-gray">
              {Object.keys(checkpointsData).length === 1
                ? null
                : Object.keys(checkpointsData).map((checkpoint, currentIdx) => (
                    <div
                      key={currentIdx}
                      className={classNames(
                        'flex space-x-0.5 md:space-x-2 items-center'
                      )}
                    >
                      <div
                        className={classNames(
                          'bg-opacity-[5%] text-[0.5rem] font-normal h-fit rounded-sm',
                          getProgressState(checkpoint) ===
                            ProgressStates.CURRENT &&
                            'text-blue-light bg-blue-light',
                          getProgressState(checkpoint) ===
                            ProgressStates.AFTER && 'text-purple bg-purple',
                          getProgressState(checkpoint) ===
                            ProgressStates.BEFORE &&
                            'text-light-gray bg-light-gray bg-opacity-100'
                        )}
                      >
                        {getProgressState(checkpoint) ===
                        ProgressStates.AFTER ? (
                          <div className="px-1 md:px-2 py-1"> &#10004; </div>
                        ) : (
                          <div className="px-[9px] py-[2px] text-xxs">
                            {currentIdx + 1}
                          </div>
                        )}
                      </div>
                      <div
                        className={classNames(
                          'text-xxs md:text-xs font-medium whitespace-nowrap',
                          getProgressState(checkpoint) ===
                            ProgressStates.CURRENT && 'text-blue-light',
                          getProgressState(checkpoint) ===
                            ProgressStates.AFTER && 'text-purple',
                          getProgressState(checkpoint) ===
                            ProgressStates.BEFORE && 'text-light-gray'
                        )}
                      >
                        {checkpointsData[checkpoint].heading}
                      </div>
                    </div>
                  ))}
            </div>
            {
              <StageArrows
                handleNextClick={handleNextClick}
                handlePreviousClick={handlePreviousClick}
                nextButtonStyling={nextButtonStyling}
                previousButtonStyling={previousButtonStyling}
                showPreviousButton={
                  !isEqual(Object.values(checkpointsData)[0], checkpoint)
                }
              />
            }
            <ProgressBar
              checkpointsData={checkpointsData}
              getProgressState={getProgressState}
            />
          </div>
        </div>
      </ModalBody>
      <ToastOutlet isMobile={+isMobile} />
    </ModalWindow>
  );
};
