import {
  convertUTCToTimezone,
  getTimezoneAbbreviation,
} from 'zync-common/helper/timezone';
import { LinkedInIconV2 } from '../../../components/icons/LinkedInIconV2';
import { PageIcon } from '../../../components/icons/PageIcon';
import { PlayerIcon } from '../../../components/icons/PlayerIcon';
import { ReelIcon } from '../../../components/icons/ReelIcon';
import { getStaticAssetUrl } from '../../../helper/getStaticAssetUrl';
import { Document, Packer, Paragraph } from 'docx';
import { saveAs } from 'file-saver';
import { SpeechIcon } from '../../../components/icons/SpeechIcon';
import { ImageIcon } from '../../../components/icons/ImageIcon';
import { NotebookIcon } from '../../../components/icons/NotebookIcon';
import { TwoPersonFilledIcon } from '../../../components/icons/TwoPersonFilledIcon';
import { MeetingControlMic } from '../../../components/icons/MeetingControlMic';
import { MailIcon } from '../../../components/icons/MailIcon';
import { AngleIcon } from '../../../components/icons/AngleIcon';
import { DownloadIcon } from '../../../components/icons/DownloadIcon';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import { TwitterIcon } from '../../../components/icons/TwitterIcon';
import mixpanel from 'mixpanel-browser';

const chatIcon = getStaticAssetUrl('icon-chat.svg');
const contactIcon = getStaticAssetUrl('icon-contact.svg');
const youtubeIcon = getStaticAssetUrl('youtube_logo.png');
export const linkedInIcon = getStaticAssetUrl('linkedIn-content-kit-icon.png');
export const instagramIcon = getStaticAssetUrl(
  'instagram-content-kit-icon.png'
);
export const tiktokIcon = getStaticAssetUrl('tiktok-content-kit-icon.png');
const compassIcon = getStaticAssetUrl('compass-icon.png');
export const podcastIcon = getStaticAssetUrl('podcast-content-kit-icon.png');
const downloadIcon = getStaticAssetUrl('download-content-kit-icon.png');

export const CONTENT_KIT_STATES = {
  incomplete: 'incomplete',
  processing: 'processing',
  complete: 'complete',
};

export const downloadGCSFile = async (url, fileName, setLoading, setError) => {
  if (setLoading) {
    setLoading(true);
  }

  try {
    const response = await fetch(url);
    if (!response.ok) {
      if (setError) {
        setError('Error downloading file, please try again.');
        return;
      }
    }
    const blob = await response.blob();
    saveAs(blob, fileName);
    if (setLoading) {
      setLoading(false);
    }
  } catch (error) {
    setError && setError('Error downloading the file, please try again.');
    setLoading && setLoading(false);
  }
};

export const isHighlightTypeMontage = (type) => type.includes('montage');

const formatFullEpisodeTranscript = (messages) => {
  const hasUserInfo = !!messages[0]?.userId;

  if (!hasUserInfo) {
    return messages.map((m) => {
      const { text } = m;
      return {
        text,
      };
    });
  } else {
    let currentUserId = messages[0]?.userId;
    let currentUserName = messages[0]?.userName;
    let newText = '';
    const newMessages = [];

    messages.forEach((m) => {
      const { userId, userName, text, message } = m;
      if (userId === currentUserId) {
        newText += (text || message) + ' ';
      } else {
        newMessages.push({
          userId: currentUserId,
          userName: currentUserName,
          text: newText,
        });
        newText = (text || message) + ' ';
        currentUserId = userId;
        currentUserName = userName;
      }
    });

    if (newText) {
      newMessages.push({
        userId: currentUserId,
        userName: currentUserName,
        text: newText,
      });
    }

    return newMessages;
  }
};

const downloadSoloTranscript = (highlights) => {
  let fileContent = '';
  highlights.forEach((h) => {
    const { sentences, title } = h || {};
    fileContent += `${title}\n\n`;
    sentences.forEach((s) => {
      const { text } = s || {};
      fileContent += `${text} `;
    });
    fileContent += `\n\n`;
  });

  const blob = new Blob([fileContent], { type: 'text/plain' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'transcript.txt';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const initializeMenus = (
  contentKitData,
  contentKitState,
  meetingSeriesId,
  shared,
  isSoloEpisode,
  workspaceId,
  workspacePlan
) => {
  const {
    recording,
    highlights = [],
    publicPageLinks,
    transcript,
    improvedTranscript,
    registrationReportCSVData = [],
    attendanceReportCSVData = [],
    chatReportCSVData = [],
    episodeQuestionsCSVData = [],
    speakerKits = [],
    teaserEditedPortrait,
    carouselPdfUrl,
    rssFeedUrl,
    episodeFileNamePrefix,
    shorts = [],
    localRecordings = [],
    episodeSettingsEpisodeVideoUrl,
  } = contentKitData || {};
  const { files, thumbnailUrl, audioUrl } = recording || {};
  const showRestrictions = isSoloEpisode && workspacePlan === PLANS.free.id;

  const portraitShortsLength = shorts.filter(
    (s) => s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_PORTRAIT
  ).length;

  const landscapeShortsLength = shorts.filter(
    (s) =>
      s.INTERESTING_MOMENTS_SHORT_MIXED_EDITED_LANDSCAPE?.aspectRatio ===
      'landscape'
  ).length;

  const portraitHighlightsLength = isSoloEpisode
    ? highlights.filter((h) => h?.HIGHLIGHT_EDITED_PORTRAIT).length
    : 0;

  const shortSoloHighlightsLength = isSoloEpisode
    ? highlights.filter((h) => {
        if (!h?.HIGHLIGHT_EDITED_PORTRAIT) return false;
        if (h?.duration) {
          return h?.duration < 60;
        }

        if (h?.startTime && h?.endTime) {
          return h?.endTime - h?.startTime < 60;
        }

        return false;
      }).length
    : 0;

  const soloEpisodeSquareHighlightsLength = highlights.filter(
    (h) => h?.HIGHLIGHT_EDITED_SQUARE
  ).length;

  const soloEpisodeLandscapeHighlightsLength = highlights.filter(
    (h) => h?.HIGHLIGHT_EDITED_LANDSCAPE
  ).length;

  const numLocalRecordings = localRecordings.length;

  const hasSoloTranscript = highlights.filter((h) => !!h?.sentences).length > 0;

  let feedPageUrl = null;

  if (highlights.length > 0) {
    const highlightId = highlights[0].highlightId;
    feedPageUrl = isSoloEpisode
      ? `/${workspaceId}/videofeed/${highlightId}`
      : `/${workspaceId}/feed/${highlightId}`;
  }

  const menus = [
    {
      type: 'links',
      header: {
        text: 'Website',
        iconUrl: compassIcon,
      },
      links: [
        {
          text: 'Episode Page',
          link: publicPageLinks?.episodePage || '',
          icon: ({ disabled }) => (
            <PageIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: (
            <div className="rotate-180">
              <AngleIcon />
            </div>
          ),
          opensNewPage: true,
          disabled: !!!publicPageLinks?.episodePage,
          additionalText: 'New',
          hidden: isSoloEpisode,
        },
        {
          text: 'Show Page',
          link: publicPageLinks?.showPage || '',
          icon: ({ disabled }) => (
            <ReelIcon
              width={36}
              height={36}
              className={`fill-purple/5 ${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'stroke-[#97A0AF]'
                  : 'stroke-purple'
              }`}
            />
          ),
          disabled: !!!publicPageLinks?.showPage,
          hoverIcon: (
            <div className="rotate-180">
              <AngleIcon />
            </div>
          ),
          opensNewPage: true,
          additionalText: 'Updated',
          hidden: isSoloEpisode,
        },
        {
          text: 'Highlights Feed',
          link: feedPageUrl || '',
          icon: ({ disabled }) => (
            <ReelIcon
              width={36}
              height={36}
              className={`fill-purple/5 ${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'stroke-[#97A0AF]'
                  : 'stroke-purple'
              }`}
            />
          ),
          hoverIcon: (
            <div className="rotate-180">
              <AngleIcon />
            </div>
          ),
          opensNewPage: true,
          disabled: !feedPageUrl,
          additionalText: 'Updated',
        },
      ],
    },
    {
      type: 'links',
      header: {
        type: 'youtube',
        iconUrl: youtubeIcon,
        showRestrictions:
          showRestrictions && soloEpisodeLandscapeHighlightsLength === 0,
      },
      links: [
        {
          text: 'Full Episode Video',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/youtube/fullVideo`
            : `/e/${meetingSeriesId}/details/publish/youtube/fullVideo`,
          icon: ({ disabled }) => (
            <PlayerIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          getNum: () => 1,
          hidden: isSoloEpisode,
        },
        {
          text: 'Highlight Clips',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/youtube/highlights`
            : `/e/${meetingSeriesId}/details/publish/youtube/highlights`,
          icon: ({ disabled }) => (
            <PlayerIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          getNum: () =>
            isSoloEpisode
              ? soloEpisodeLandscapeHighlightsLength
              : highlights.length,
          disabled: isSoloEpisode
            ? soloEpisodeLandscapeHighlightsLength === 0
            : highlights.length === 0,
        },
        {
          text: 'Youtube Shorts',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/youtube/shorts`
            : `/e/${meetingSeriesId}/details/publish/youtube/shorts`,
          icon: ({ disabled }) => (
            <PlayerIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          getNum: () => {
            if (isSoloEpisode) {
              return shortSoloHighlightsLength;
            }
            let num = 0;
            if (teaserEditedPortrait) num++;
            num += portraitShortsLength;
            num += portraitHighlightsLength;
            return num;
          },
          disabled: isSoloEpisode
            ? shortSoloHighlightsLength === 0
            : !teaserEditedPortrait && portraitShortsLength === 0,
          noShortsAndLocalRecordings: isSoloEpisode
            ? shortSoloHighlightsLength === 0 && numLocalRecordings === 0
            : portraitShortsLength === 0 && numLocalRecordings === 0,
        },
      ],
    },
    {
      type: 'links',
      hidden: isSoloEpisode,
      header: {
        text: 'Podcast',
        iconUrl: podcastIcon,
      },
      links: [
        {
          text: 'Podcast Page',
          link: publicPageLinks?.podcastPage || '',
          icon: ({ disabled }) => (
            <PageIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: (
            <div className="rotate-180">
              <AngleIcon />
            </div>
          ),
          opensNewPage: true,
          disabled: !!!publicPageLinks?.podcastPage,
        },
        {
          text: 'RSS Feed',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/rssfeed`
            : `/e/${meetingSeriesId}/details/publish/rssfeed`,
          icon: ({ disabled }) => (
            <PageIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          disabled: !rssFeedUrl,
        },
      ],
    },
    {
      type: 'links',
      header: {
        text: 'LinkedIn',
        iconUrl: linkedInIcon,
      },
      links: [
        {
          text: 'LinkedIn Video Clips',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/linkedIn/clips`
            : `/e/${meetingSeriesId}/details/publish/linkedIn/clips`,
          icon: ({ disabled }) => (
            <PlayerIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          getNum: () => landscapeShortsLength,
          disabled: landscapeShortsLength === 0,
          hidden: isSoloEpisode,
        },
        {
          text: 'LinkedIn Posts',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/linkedIn/posts`
            : `/e/${meetingSeriesId}/details/publish/linkedIn/posts`,
          icon: ({ disabled }) => (
            <LinkedInIconV2
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          getNum: () =>
            isSoloEpisode
              ? soloEpisodeSquareHighlightsLength
              : highlights.filter(
                  (h) => h.autoGenerateSocialMediaVideo === 'linkedIn'
                ).length,
          disabled: isSoloEpisode
            ? soloEpisodeSquareHighlightsLength === 0
            : highlights.filter(
                (h) => h.autoGenerateSocialMediaVideo === 'linkedIn'
              ).length === 0,
        },
        {
          text: 'Carousel',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/linkedIn/carousel`
            : `/e/${meetingSeriesId}/details/publish/linkedIn/carousel`,
          icon: ({ disabled }) => (
            <LinkedInIconV2
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: <DownloadIcon />,
          disabled: !carouselPdfUrl,
          hidden: isSoloEpisode,
        },
      ],
    },
    {
      type: 'links',
      header: {
        text: 'Twitter',
        showRestrictions:
          showRestrictions && soloEpisodeLandscapeHighlightsLength === 0,
        iconElement: () => <TwitterIcon width="40px" height="40px" />,
      },
      links: [
        {
          text: 'Twitter/X Video Clips',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/twitter/video-clips`
            : `/e/${meetingSeriesId}/details/publish/twitter/video-clips`,
          icon: ({ disabled }) => (
            <PlayerIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          getNum: () => landscapeShortsLength,
          disabled: landscapeShortsLength === 0,
        },
        {
          text: 'Twitter/X Tweets',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/twitter/tweets`
            : `/e/${meetingSeriesId}/details/publish/twitter/tweets`,
          icon: ({ disabled }) => <TwitterIcon width="20px" height="20px" />,
          getNum: () =>
            isSoloEpisode
              ? soloEpisodeLandscapeHighlightsLength
              : highlights.filter(
                  (h) => h.autoGenerateSocialMediaVideo === 'twitter'
                ).length,
          disabled: isSoloEpisode
            ? soloEpisodeLandscapeHighlightsLength === 0
            : highlights.filter(
                (h) => h.autoGenerateSocialMediaVideo === 'twitter'
              ).length === 0,
        },
      ],
    },
    {
      type: 'downloads',
      hidden: isSoloEpisode,
      header: {
        text: 'Audience Connect',
        iconUrl: contactIcon,
      },
      downloads: [
        {
          text: 'Registration Report',
          type: 'csv',
          getCSVData: () => registrationReportCSVData || [],
          getNum: () =>
            registrationReportCSVData
              ? registrationReportCSVData.length - 1
              : 0,
          icon: ({ disabled }) => (
            <NotebookIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: <DownloadIcon />,
          disabled: registrationReportCSVData
            ? registrationReportCSVData.length < 2
            : true,
          getFileName: () =>
            episodeFileNamePrefix
              ? `${episodeFileNamePrefix}_registration`
              : 'Registration Report',
        },
        {
          text: 'Attendance Report',
          type: 'csv',
          getCSVData: () => attendanceReportCSVData || [],
          getNum: () =>
            attendanceReportCSVData ? attendanceReportCSVData.length - 1 : 0,
          icon: ({ disabled }) => (
            <TwoPersonFilledIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: <DownloadIcon />,
          disabled: attendanceReportCSVData
            ? attendanceReportCSVData.length < 2
            : true,
          getFileName: () =>
            episodeFileNamePrefix
              ? `${episodeFileNamePrefix}_attendance`
              : 'Attendance Report',
        },
      ],
    },
    {
      type: 'links',
      header: {
        text: 'Instagram',
        iconUrl: instagramIcon,
        showRestrictions: showRestrictions && shortSoloHighlightsLength === 0,
      },
      links: [
        {
          text: 'Reels',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/instagram/reels`
            : `/e/${meetingSeriesId}/details/publish/instagram/reels`,
          icon: ({ disabled }) => (
            <PlayerIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          getNum: () => {
            if (isSoloEpisode) {
              return shortSoloHighlightsLength;
            }
            let num = 0;
            if (teaserEditedPortrait) num++;
            num += portraitShortsLength;
            return num;
          },
          disabled: isSoloEpisode
            ? shortSoloHighlightsLength === 0
            : !teaserEditedPortrait && portraitShortsLength === 0,
          noShortsAndLocalRecordings:
            portraitShortsLength === 0 && numLocalRecordings === 0,
        },
      ],
    },
    {
      type: 'links',
      header: {
        text: 'TikTok',
        iconUrl: tiktokIcon,
        showRestrictions: showRestrictions && shortSoloHighlightsLength === 0,
      },
      links: [
        {
          text: 'Video Clips',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/tiktok/clips`
            : `/e/${meetingSeriesId}/details/publish/tiktok/clips`,
          icon: ({ disabled }) => (
            <PlayerIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          getNum: () => {
            let num = 0;
            if (teaserEditedPortrait) num++;
            num += portraitShortsLength;
            num += portraitHighlightsLength;
            return num;
          },
          disabled: isSoloEpisode
            ? portraitHighlightsLength === 0
            : !teaserEditedPortrait && portraitShortsLength === 0,
          noShortsAndLocalRecordings: isSoloEpisode
            ? portraitHighlightsLength === 0 && numLocalRecordings === 0
            : portraitShortsLength === 0 && numLocalRecordings === 0,
        },
      ],
    },
    {
      type: 'downloads',
      header: {
        text: 'Download',
        iconUrl: downloadIcon,
      },
      downloads: [
        {
          text: 'Full Episode Video',
          type: 'url',
          getDownloadUrl: (title, setIsLoading) => {
            const fileName = `${title}.mp4`;
            if (episodeSettingsEpisodeVideoUrl) {
              downloadGCSFile(
                episodeSettingsEpisodeVideoUrl,
                fileName,
                setIsLoading
              );
              return;
            }
            if (!files) return null;
            const highQualityFile = files.find(
              (file) => file.name === 'high.mp4'
            );
            const mediumQualityFile = files.find(
              (file) => file.name === 'medium.mp4'
            );
            const lowQualityFile = files.find(
              (file) => file.name === 'low.mp4'
            );

            if (highQualityFile) {
              downloadGCSFile(
                highQualityFile.downloadUrl,
                fileName,
                setIsLoading
              );
              return;
            } else if (mediumQualityFile) {
              downloadGCSFile(
                mediumQualityFile.downloadUrl,
                fileName,
                setIsLoading
              );
              return;
            } else if (lowQualityFile) {
              downloadGCSFile(
                lowQualityFile.downloadUrl,
                fileName,
                setIsLoading
              );
              return;
            } else {
              return;
            }
          },
          icon: ({ disabled }) => (
            <PlayerIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: <DownloadIcon />,
          hidden: isSoloEpisode,
        },
        {
          text: 'Full Episode Audio',
          type: 'gcs',
          getGCSDownload: (setIsLoading) => {
            downloadGCSFile(
              audioUrl,
              episodeFileNamePrefix
                ? `${episodeFileNamePrefix}_audio.mp3`
                : 'audio-recording.mp3',
              setIsLoading
            );
          },
          icon: ({ disabled }) => (
            <MeetingControlMic
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: <DownloadIcon />,
          hidden: isSoloEpisode,
        },
        {
          text: 'Transcript',
          type: 'word',
          generateWordDocument: () => {
            if (!transcript && !improvedTranscript) return null;

            let paragraphs = null;

            if (improvedTranscript) {
              const formattedTranscript =
                formatFullEpisodeTranscript(improvedTranscript);

              paragraphs = formattedTranscript.map((m) => {
                const { userName, text } = m;
                return new Paragraph({
                  text: userName ? `${userName}: ${text}` : `${text}`,
                });
              });
            } else {
              const timezoneAbbreviation = getTimezoneAbbreviation(
                'Universal Time Coordinated'
              );

              paragraphs = transcript.map((messageObject) => {
                const { timestamp, userName, message } = messageObject;
                return new Paragraph({
                  text: `${convertUTCToTimezone(
                    timestamp,
                    'Universal Time Coordinated',
                    'time'
                  )} ${timezoneAbbreviation} - ${userName}: ${message}`,
                });
              });
            }

            if (!paragraphs) return;

            const doc = new Document({
              sections: [
                {
                  children: paragraphs,
                },
              ],
            });

            Packer.toBlob(doc).then((blob) => {
              saveAs(
                blob,
                episodeFileNamePrefix
                  ? `${episodeFileNamePrefix}_transcript`
                  : 'Voice Transcript.docx'
              );
            });
          },
          icon: ({ disabled }) => (
            <SpeechIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: <DownloadIcon />,
          hidden: isSoloEpisode,
        },
        {
          text: 'Chat Transcript',
          type: 'csv',
          getCSVData: () => chatReportCSVData || [],
          icon: ({ disabled }) => (
            <SpeechIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: <DownloadIcon />,
          disabled: !chatReportCSVData,
          getFileName: () =>
            episodeFileNamePrefix
              ? `${episodeFileNamePrefix}_chat`
              : 'Chat Transcript',
          hidden: isSoloEpisode,
        },
        {
          text: 'Audience Questions',
          type: 'csv',
          getCSVData: () => episodeQuestionsCSVData || [],
          icon: ({ disabled }) => (
            <SpeechIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          getNum: () =>
            episodeQuestionsCSVData ? episodeQuestionsCSVData.length - 1 : 0,
          hoverIcon: <DownloadIcon />,
          disabled: !episodeQuestionsCSVData,
          getFileName: () =>
            episodeFileNamePrefix
              ? `${episodeFileNamePrefix}_questions`
              : 'Audience Questions',
          hidden: isSoloEpisode,
        },
        {
          text: 'Event Poster',
          type: 'gcs',
          getGCSDownload: (setIsLoading) => {
            downloadGCSFile(
              thumbnailUrl,
              episodeFileNamePrefix
                ? `${episodeFileNamePrefix}_poster.png`
                : 'event-poster.png',
              setIsLoading
            );
          },
          icon: ({ disabled }) => (
            <ImageIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: <DownloadIcon />,
          hidden: isSoloEpisode,
        },
        ...localRecordings.map((localRecording) => ({
          text: isSoloEpisode
            ? localRecording?.title
              ? localRecording?.title
              : 'High Definition Video'
            : `${localRecording.userFirstName}'s High Quality Video`,
          type: 'url',
          getDownloadUrl: (title, setIsLoading) => {
            mixpanel.track('Content Kit: Video Download Clicked');
            if (!localRecording.files) return;
            const highQualityFile = localRecording.files.find(
              (file) => file.name === 'high.mp4'
            );
            const mediumQualityFile = localRecording.files.find(
              (file) => file.name === 'medium.mp4'
            );
            const lowQualityFile = localRecording.files.find(
              (file) => file.name === 'low.mp4'
            );

            const fileName = `${title}.mp4`;

            if (highQualityFile) {
              downloadGCSFile(
                highQualityFile.downloadUrl,
                fileName,
                setIsLoading
              );
              return;
            } else if (mediumQualityFile) {
              downloadGCSFile(
                mediumQualityFile.downloadUrl,
                fileName,
                setIsLoading
              );
              return;
            } else if (lowQualityFile) {
              downloadGCSFile(
                lowQualityFile.downloadUrl,
                fileName,
                setIsLoading
              );
              return;
            } else {
              return;
            }
          },
          icon: ({ disabled }) => (
            <PlayerIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          hoverIcon: <DownloadIcon />,
        })),
        {
          text: 'Transcript',
          type: 'txt',
          icon: ({ disabled }) => (
            <SpeechIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          generateTextFile: () => {
            downloadSoloTranscript(highlights);
          },
          hoverIcon: <DownloadIcon />,
          hidden: !isSoloEpisode || !hasSoloTranscript,
        },
      ],
    },
  ];

  const speakerPageLinks = speakerKits.map((sk) => ({
    text: `${sk.fullName} Kit`,
    link: sk.speakerPageLink,
    icon: ({ disabled }) => (
      <PageIcon
        className={`${
          contentKitState === CONTENT_KIT_STATES.incomplete || disabled
            ? 'fill-[#97A0AF]'
            : 'fill-purple'
        }`}
      />
    ),
    hoverIcon: (
      <div className="rotate-180">
        <AngleIcon />
      </div>
    ),
    opensNewPage: true,
  }));

  menus.push({
    type: 'links',
    header: {
      text: 'Speaker Connect',
      iconUrl: contactIcon,
    },
    hidden: isSoloEpisode,
    links: [
      {
        text: 'Speaker Emails',
        link: shared
          ? `/e/${meetingSeriesId}/contentkit/speakerEmails`
          : `/e/${meetingSeriesId}/details/publish/speakerEmails`,
        icon: ({ disabled }) => (
          <MailIcon
            className={`${
              contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                ? 'fill-[#97A0AF]'
                : 'fill-purple'
            }`}
          />
        ),
        disabled: speakerKits.length === 0,
      },
      ...speakerPageLinks,
    ],
  });

  if (highlights.filter((h) => isHighlightTypeMontage(h.type)).length > 0) {
    menus.push({
      type: 'links',
      header: {
        text: 'Experimental Content Kit',
        iconUrl: chatIcon,
      },
      links: [
        {
          text: 'Composite Highlights',
          link: shared
            ? `/e/${meetingSeriesId}/contentkit/experimental`
            : `/e/${meetingSeriesId}/details/publish/experimental`,
          icon: ({ disabled }) => (
            <PlayerIcon
              className={`${
                contentKitState === CONTENT_KIT_STATES.incomplete || disabled
                  ? 'fill-[#97A0AF]'
                  : 'fill-purple'
              }`}
            />
          ),
          getNum: () =>
            highlights.filter((h) => isHighlightTypeMontage(h.type)).length,
          disabled:
            highlights.filter((h) => isHighlightTypeMontage(h.type)).length ===
            0,
        },
      ],
    });
  }

  return menus;
};
