import { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { combineDayAndTime, getLocalTimezoneAreaName } from '../../helper/rsvp';
import { useSelector } from 'react-redux';
import {
  createNewSeriesApi,
  fetchUserApi,
  generateEventPosterInBackgroundApi,
  getSpeakersByIdApi,
  triggerAiSoloEpisode,
  triggerPreparationKitApi,
  updateCustomerEngagementStagesApi,
} from '../../helper/api';
import mixpanel from 'mixpanel-browser';
import { getMeetingSeriesProps } from 'zync-common/tracking';
import { fromError, logerror, loginfo } from '../../helper/contextualLogger';
import { cloneDeep } from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import { makeRandomId } from '../../helper';
import { DynamicBlocks } from '../../blocks/helper/dynamicBlockVariables';
import { createEpisodeWithSpeakers } from './createEpisodeWithSpeakers';
import { createEpisodeWithoutSpeakers } from './createEpisodeWithoutSpeakers';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import { presenterTypes } from '../../components/series/settings/EventPresenters';
import { episodeTypes } from 'zync-common/types';
import { WorkspaceContext } from '../../App';
import { createPlaceholderEpisode } from '../SoloWelcome';
import zyncCustomerEngagementStages from 'zync-common/hubspot/zyncCustomerEngagementStages';

const convertedCurrentScheduledEvent = () => {
  return {
    startTime: '2:00 PM',
    daySelected: moment().add(1, 'days'),
    endTime: '3:00 PM',
    timezoneSelected: getLocalTimezoneAreaName(),
  };
};

export const buildNewBlockId = (blockId) =>
  [blockId.split('-')[0], makeRandomId(8)].join('-');

export const createNewQuestionSlide = (questionTemplate, question) => {
  const newQuestionSlide = cloneDeep(questionTemplate);

  newQuestionSlide.sceneId = makeRandomId(8);
  newQuestionSlide.slideName = `${question.text}`;
  newQuestionSlide.title = ` ${question.text}`;

  if (question.hostNote) {
    newQuestionSlide.hostNote = question.hostNote;
  }

  newQuestionSlide.slideConfig.slideBlocks =
    newQuestionSlide.slideConfig.slideBlocks.map((block) => ({
      ...block,
      blockInstanceId: buildNewBlockId(block.blockInstanceId || block.blockId),
    }));

  const newQuestionBlock = newQuestionSlide.slideConfig.slideBlocks.find(
    (block) => block.blockId === 'singleQuestion'
  );

  if (newQuestionBlock) {
    newQuestionBlock.settings.questionText = question.text;
    newQuestionBlock.settings.questionAsker = question.asker;
  }

  if (question.title) {
    const questionTitleBlock = newQuestionSlide.blocks.find(
      (block) => block.blockId === 'textbox'
    );

    if (questionTitleBlock) {
      questionTitleBlock.settings.text = question.title;
    }
  }

  if (question.videoScript) {
    const questionBlock = newQuestionSlide.blocks.find(
      (block) => block.blockId === 'tooltipPane'
    );

    if (questionBlock) {
      questionBlock.settings.content = Array.isArray(question.videoScript)
        ? question.videoScript.join(', ')
        : question.videoScript;
    }
  }

  return newQuestionSlide;
};

export const createNewPresenterSlide = (presenterTemplate, presenter) => {
  const newPresenterSlide = cloneDeep(presenterTemplate);

  newPresenterSlide.sceneId = makeRandomId(8);

  newPresenterSlide.slideName = `Speaker Intro: ${
    presenter.name || presenter.fullName
  }`;
  newPresenterSlide.title = `Speaker Intro: ${
    presenter.name || presenter.fullName
  }`;

  newPresenterSlide.slideConfig.slideBlocks =
    newPresenterSlide.slideConfig.slideBlocks.map((block) => ({
      ...block,
      blockInstanceId: buildNewBlockId(block.blockInstanceId || block.blockId),
    }));

  const speakerVideoBlock = newPresenterSlide.slideConfig.slideBlocks.find(
    (block) =>
      block.blockId === 'singlevideo' && block.settings.role === 'speaker'
  );

  if (speakerVideoBlock) {
    speakerVideoBlock.settings.userId = presenter.emailAddress;
  }

  const newBlocks = new DynamicBlocks(
    newPresenterSlide.slideConfig.slideBlocks
  );

  newBlocks.replaceWith('{speaker_1_name}', `{${presenter._id}_name}`);
  newBlocks.replaceWith('{speaker_1_bio}', `{${presenter._id}_bio}`);

  newPresenterSlide.slideConfig.slideBlocks = newBlocks.getAll();

  return newPresenterSlide;
};

export const emptyScheduledEvent = {
  endDate: null,
  registeredAttendees: [],
  sendgridListId: null,
  sendgridScheduledSends: [],
  startDate: null,
  timezone: null,
};
export const getScheduledEvent = ({
  daySelected,
  startTime,
  endTime,
  timezoneSelected,
}) => ({
  currentDate: moment.tz(timezoneSelected),
  startDate: combineDayAndTime(daySelected, startTime, timezoneSelected),
  endDate: combineDayAndTime(daySelected, endTime, timezoneSelected),
  timezone: timezoneSelected,
});

export const useMeetingCreator = ({
  workspaceId,
  workspacePlan,
  defaultHostId,
  episodeType,
  onClose,
}) => {
  const userId = useSelector((st) => st.auth?.user?.userId);
  const history = useHistory();
  const [isAddingSpeaker, setIsAddingSpeaker] = useState(false);
  const [isMissingPresenterError, setIsMissingPresenterError] = useState(false);
  const [visualProgress, setVisualProgress] = useState('');

  const userName = useSelector((state) => state.auth.user?.userName);
  const emailAddress = useSelector((state) => state.auth.user?.emailAddress);
  const bio = useSelector((state) => state.auth.user?.bio);
  const avatarUrl = useSelector((state) => state.auth.user?.avatarUrl);

  const { workspace } = useContext(WorkspaceContext);
  const defaultSpeakerId = workspace?.soloSettings?.defaultSpeaker?._id;

  useEffect(() => {
    const getDefaultHost = async () => {
      const user = await fetchUserApi(defaultHostId);

      const newHost = {
        linkedInUrl: user?.linkedInUrl || '',
        fullName: user?.userName || '',
        jobTitle: user?.bio || '',
        presenterPictureUrl: user?.avatarUrl || null,
        emailAddress: user?.emailAddress,
        invited: false,
        type: presenterTypes.HOST,
      };

      const newHostObject = newHost || {
        emailAddress: emailAddress,
        fullName: userName,
        jobTitle: bio,
        linkedInUrl: '',
        presenterPictureUrl: avatarUrl,
        type: 'host',
      };

      setEventHost(newHostObject);

      if (episodeType === episodeTypes.solo && defaultSpeakerId) {
        try {
          const speaker = await getSpeakersByIdApi(defaultSpeakerId);

          if (speaker) {
            setEventPresenters([speaker]);
          } else {
            setIsMissingPresenterError(true);
          }
        } catch (error) {
          setEventPresenters([]);
          logerror({
            ...fromError(error),
            message:
              "We could not fetch speaker when creating solo episode. The episode won't have speaker associated",
          });
        }
      }
    };
    getDefaultHost();
  }, [
    defaultSpeakerId,
    emailAddress,
    userName,
    bio,
    avatarUrl,
    defaultHostId,
    episodeType,
  ]);

  const [title, setTitleRaw] = useState('');
  const setTitle = (value) => {
    setTitleRaw(value);
  };
  const [eventHost, setEventHost] = useState({});

  const [temporaryEventPresenter, setTemporaryEventPresenter] = useState({
    emailAddress: '',
    fullName: '',
    jobTitle: '',
    linkedInUrl: '',
    presenterPictureUrl: '',
    type: 'speaker',
  });

  const [eventPresenters, setEventPresenters] = useState([]);
  const [scheduledEvent, setScheduledEvent] = useState(
    convertedCurrentScheduledEvent
  );
  const [isCreatingWithDate, setIsCreatingWithDate] = useState(false);
  const [whenDateWasSetForTheFirstTime, setWhenDateWasSetForTheFirstTime] =
    useState(false);

  const newMeetingSeriesId = useRef(makeRandomId(8));

  const [questions, setQuestions] = useState([
    {
      text: 'Your predictions for the next year.',
      id: makeRandomId(6),
      timestamp: Date.now(),
      asker: null,
      moderatorQuestion: true,
      AIGenerated: false,
      index: 0,
    },
  ]);

  const createNewEvent = async () => {
    if (!title) {
      return;
    }

    try {
      const episodeBuilder = eventPresenters.length
        ? createEpisodeWithSpeakers
        : createEpisodeWithoutSpeakers;

      const timelineStyle = workspace?.settings?.defaultTimelineStyle;

      const newTemplate = await episodeBuilder({
        title,
        eventSpeakers: eventPresenters,
        eventHost,
        questions,
        timelineStyle,
      });

      const series = await createNewSeriesApi(
        workspaceId,
        emailAddress,
        title,
        '' /* empty description */,
        'rsvp',
        newTemplate,
        isCreatingWithDate
          ? getScheduledEvent(scheduledEvent)
          : emptyScheduledEvent,
        undefined,
        newMeetingSeriesId.current
      );

      generateEventPosterInBackgroundApi(series.meetingSeriesId);

      updateCustomerEngagementStagesApi(
        userId,
        zyncCustomerEngagementStages.CREATED_SESSION.stage
      );

      if (workspacePlan === PLANS.admin.id) {
        triggerPreparationKitApi(series.meetingSeriesId, workspaceId, userId);
      }

      mixpanel.track('New Session: Session Created', {
        ...getMeetingSeriesProps(series),
        distinct_id: userId,
      });

      history.push(`/e/${series.meetingSeriesId}/details/plan`);
    } catch (err) {
      logerror(fromError(err));
    }
  };

  /*const createNewSoloEpisode = async () => {
    if (!title) {
      return;
    }

    try {
      const newTemplate = await createSoloInterviewEpisode({
        title,
        eventSpeakers: eventPresenters,
        questions,
      });

      const series = await createNewSeriesApi(
        workspaceId,
        emailAddress,
        title,
        '' // empty description ,
        'rsvp',
        newTemplate,
        isCreatingWithDate
          ? getScheduledEvent(scheduledEvent)
          : emptyScheduledEvent,
        undefined,
        newMeetingSeriesId.current,
        false,
        undefined,
        undefined,
        undefined,
        episodeType
      );

      if (workspacePlan === PLANS.admin.id) {
        triggerPreparationKitApi(series.meetingSeriesId, workspaceId, userId);
      }

      updateCustomerEngagementStagesApi(
        userId,
        zyncCustomerEngagementStages.CREATED_SESSION.stage
      );

      mixpanel.track('New Session: Session Created', {
        ...getMeetingSeriesProps(series),
        distinct_id: userId,
      });

      history.push(`/e/${series.meetingSeriesId}/details/prepare`);
    } catch (err) {
      logerror(fromError(err));
    }
  };*/

  const createNewSoloEpisodeBlogAndProfileBased = async ({
    newBlogPostText,
    newIdeaText,
    topic,
  } = {}) => {
    setVisualProgress('Getting speaker information...');
    const speaker = await getSpeakersByIdApi(defaultSpeakerId);

    if (!speaker) {
      return;
    }

    setVisualProgress('Creating episode based on your selection...');

    await createPlaceholderEpisode({
      emailAddress: userId,
      meetingSeriesId: newMeetingSeriesId.current + '_placeholder',
      workspaceId,
    });

    loginfo({
      message: `calling triggerAiSoloEpisode in useMeetingCreator`,
      workspaceId,
      meetingSeriesId: newMeetingSeriesId.current,
      userId,
    });

    triggerAiSoloEpisode({
      meetingSeriesId: newMeetingSeriesId.current,
      workspaceId,
      userId,
      blogPostText: newBlogPostText,
      newIdeaText,
      topic,
    })
      .then()
      .catch();

    updateCustomerEngagementStagesApi(
      userId,
      zyncCustomerEngagementStages.CREATED_SESSION.stage
    );

    onClose();
  };

  const configByEpisodeTypes = {
    [episodeTypes.default]: {
      eventCreatorFn: createNewEvent,
    },
    [episodeTypes.solo]: {
      eventCreatorFn: createNewSoloEpisodeBlogAndProfileBased,
    },
    [episodeTypes.soloBlogBased]: {
      eventCreatorFn: createNewSoloEpisodeBlogAndProfileBased,
    },
    [episodeTypes.soloProfileBased]: {
      eventCreatorFn: createNewSoloEpisodeBlogAndProfileBased,
    },
    [episodeTypes.soloIdeaBased]: {
      eventCreatorFn: createNewSoloEpisodeBlogAndProfileBased,
    },
  };

  return {
    newMeetingSeriesId: newMeetingSeriesId.current,

    title,
    setTitle,

    eventHost,
    setEventHost,

    temporaryEventPresenter,
    setTemporaryEventPresenter,

    eventPresenters,
    setEventPresenters,

    scheduledEvent,
    setScheduledEvent,

    questions,
    setQuestions,

    createNewEvent: configByEpisodeTypes[episodeType].eventCreatorFn,

    isCreatingWithDate,
    setIsCreatingWithDate,

    whenDateWasSetForTheFirstTime,
    setWhenDateWasSetForTheFirstTime,

    isAddingSpeaker,
    setIsAddingSpeaker,

    isMissingPresenterError,

    visualProgress,
  };
};
