import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import classNames from '../../helper/classNames';
import { MenuLeftArrow } from '../../components/icons/MenuLeftArrow';
import { getHomeUrl } from '../../helper';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import { useAuthoringTool } from '../../components/authoring/hooks';
import { useRichTextEditor } from '../../hooks/useRichTextEditor';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import moment from 'moment';
import { getWorkspaceIdFromUrl } from '../../hooks/useTemplateCreation';
import { Button } from '../../components/common/Button';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { useWorkspacePlan } from '../../hooks/useWorkspacePlan';

const logoImage = getStaticAssetUrl('logo_text.png');

/**
 * Pages that implement the Navbar.
 */
export const pageTitles = {
  home: 'Home',
  meeting: 'Meeting',
  authoring: 'Authoring',
  highlights: 'Highlights',
  workspaceDetails: 'Workspace Details',
  guestQuestions: 'Guest Questions',
};

export const Navbar = ({ children }) => {
  return (
    <nav
      className={classNames(
        'w-full bg-white shadow-xl flex justify-center sticky top-0 z-50'
      )}
    >
      <ul className="flex items-center justify-between bg-white w-11/12 h-16">
        <li className="w-full">{children}</li>
      </ul>
    </nav>
  );
};

export const PreviousPage = ({ currentPage, previousRoute = '/' }) => {
  const {
    currentSeries: { title },
  } = useAuthoringTool();
  const { textValue } = useRichTextEditor({ initialValue: title });

  const workspaceId = getWorkspaceIdFromUrl();

  if (previousRoute === '/' && workspaceId) {
    previousRoute += `?workspaceId=${workspaceId}`;
  }

  const pageTitlesToPreviousPage = {
    [pageTitles.home]: pageTitles.home,
    [pageTitles.meeting]: pageTitles.home,
    [pageTitles.workspaceDetails]: pageTitles.home,
    [pageTitles.authoring]: textValue,
    [pageTitles.highlights]: pageTitles.meeting,
    [pageTitles.guestQuestions]: 'Back',
  };

  return (
    <Link
      to={previousRoute}
      className="flex items-center gap-3 my-3 cursor-pointer"
    >
      <MenuLeftArrow width="20" height="14" color="#00000099" />
      <span className="font-medium text-lg text-black text-opacity-60 capitalize">
        {pageTitlesToPreviousPage[currentPage]}
      </span>
    </Link>
  );
};

export const HomePageLogo = ({ workspaceId }) => {
  return (
    <div className="w-20">
      <Link to={`/?workspaceId=${workspaceId}`} className="">
        <img className="cursor-pointer" src={logoImage} alt="icon" />
      </Link>
    </div>
  );
};

export const signout = (auth, dispatch, logout, returnToHomePage = true) => {
  const { authenticated, user } = auth;
  const { registered } = user || {};

  if (authenticated && registered) {
    const signout = (dispatch) => {
      logout();
      dispatch({
        type: 'LOGOUT',
      });
      if (returnToHomePage) window.location.href = getHomeUrl();
    };
    signout(dispatch);
  }
};

export const NavbarPlanInformation = ({ workspaceId }) => {
  const userId = useSelector((st) => st.auth?.user?.userId);
  const history = useHistory();
  const redirectLink = `/workspace/billing?workspaceId=${workspaceId}`;

  const { isTrial, plan, currentPeriodEnd, workspacePlanLabel } =
    useWorkspacePlan();

  return (
    <>
      {(isTrial || plan === PLANS.free.id) && (
        <Button
          color={Button.colors.ORANGE}
          padding={Button.padding.SEMI_SMALL}
          onClick={() => {
            mixpanel.track('Portal: Clicked Upgrade', {
              distinct_id: userId,
            });
            history.push(`/workspace/billing?workspaceId=${workspaceId}`);
          }}
        >
          <div className="flex gap-1.5 items-center">
            <img
              width={24}
              height={24}
              src={getStaticAssetUrl('chargingIcon.png')}
              alt=""
            />
            <span className="text-sm">Upgrade</span>
          </div>
        </Button>
      )}
      {currentPeriodEnd && workspacePlanLabel && isTrial && (
        <Link
          to={redirectLink}
          className="text-blue-dark font-medium flex justify-center items-center text-xs cursor-pointer underline decoration-blue-dark"
        >
          Zync {workspacePlanLabel} valid until{' '}
          {moment(currentPeriodEnd).format('MMM D, yyyy')}
        </Link>
      )}
    </>
  );
};
