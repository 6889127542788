import { useContext } from 'react';
import { WorkspaceContext } from '../App';

export const useWorkspacePlan = (providedWorkspace) => {
  const {
    workspace,
    isLoading,
    workspacePlanCache = {},
    subscription = {},
  } = useContext(WorkspaceContext);

  if (providedWorkspace) {
    return {
      isAppSumo:
        providedWorkspace?.workspacePlanCache?.vendor !== undefined &&
        providedWorkspace?.workspacePlanCache?.vendor === 'App Sumo',
      plan: providedWorkspace?.workspacePlanCache.workspacePlan,
      subscription: providedWorkspace?.subscription,
      features: providedWorkspace?.workspacePlanCache?.features,
      ...providedWorkspace?.workspacePlanCache,
    };
  }

  if (!workspace?.workspaceId) {
    return {
      features: {},
    };
  }

  const { features = {} } = workspacePlanCache;

  return {
    isLoading,
    isAppSumo:
      workspacePlanCache.vendor !== undefined &&
      workspacePlanCache.vendor === 'App Sumo',
    plan: workspacePlanCache.workspacePlan,
    subscription,
    features,
    ...workspacePlanCache,
  };
};
