import React, { useEffect, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { usePortal } from '../hooks/usePortal';
import { getHomeUrl, isMobile } from '../helper';
import { LogoSpinnerFullCentered } from '../components/LogoSpinner';
import {
  FuturisticBackground,
  IsometricShapesDecoration,
} from '../components/FuturisticBackground';
import { HomeNavbar } from './Navbar/HomeNavbar';
import { getMeetingTitle } from '../components/richText/helpers';
import { Button } from '../components/common/Button';
import { useSelectDefaultWorkspace } from '../hooks/useSelectDefaultWorkspace';
import { CreateNewEventModal } from './Portal/CreateNewEventModal';
import { EventCard } from './Portal/EventCard';
import { isDefaultBrandKit } from 'zync-common/helper/brandKit';
import { DesktopOnlyModal } from '../components/DesktopOnlyModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SettingsIcon } from '../components/icons/SettingsIcon';
import { lightenOrDarkenColor } from '../helper/lightenOrDarkenColor';
import { TwoPersonPlusIcon } from '../components/icons/TwoPersonPlusIcon';
import { MovieFilmIcon } from '../components/icons/MovieFilmIcon';
import { ShutterIcon } from '../components/icons/ShutterIcon';
import moment from 'moment';
import classNames from '../helper/classNames';
import { AngleIcon } from '../components/icons/AngleIcon';
import { Notification, Notifications } from '../components/Notifications';
import { PrivacyPolicyConsentModal } from '../components/PrivacyPolicyConsentModal';
import { getHostedByText } from './Workspaces/WorkspaceSettings';
import { episodeTypes } from 'zync-common/types';
import { PLANS } from 'zync-common/zyncCustomerPlans';
import { updateCustomerEngagementStagesApi } from '../helper/api';
import zyncCustomerEngagementStages from 'zync-common/hubspot/zyncCustomerEngagementStages';
const checkHasContentKit = (episode) =>
  episode.contentKitStatus !== null &&
  episode.contentKitStatus.message !== 'No highlights available';

const checkIsIncomplete = (episode) => {
  const hasNoContentKit = !checkHasContentKit(episode);
  const isPastEndDate =
    moment().diff(moment(episode.scheduledEvent.endDate), 'hours') >= 24;

  return (hasNoContentKit && isPastEndDate) || hasNoContentKit;
};

const checkIsUpcoming = (episode) => {
  const hasNoDateSet =
    !episode.scheduledEvent.endDate || !episode.scheduledEvent.startDate;

  if (hasNoDateSet) {
    return true;
  }

  const hasNoContentKit = !checkHasContentKit(episode);
  const today = moment();
  const endDate = moment(episode.scheduledEvent.endDate);

  return hasNoContentKit && today.isBefore(endDate);
};
const checkIsCompletedRecently = (episode) => {
  const hasContentKit = checkHasContentKit(episode);

  const TIME_CONSIDERED_COMPLETED_RECENTLY = 2; // 2 days with fractions

  const timeDifference = moment().diff(
    moment(episode.scheduledEvent.endDate),
    'days'
  );

  return (
    hasContentKit &&
    timeDifference >= 0 &&
    timeDifference < TIME_CONSIDERED_COMPLETED_RECENTLY
  );
};

const checkIsSoloEpisode = (episode) => {
  return episode?.settings?.episodeType === episodeTypes.solo;
};

const checkIsCompletedLater = (episode) => {
  const hasContentKit = checkHasContentKit(episode);
  return hasContentKit && !checkIsCompletedRecently(episode);
};

const sortByStartDate = (episodes) =>
  episodes.slice().sort((a, b) => {
    if (!a.scheduledEvent.startDate) {
      return 1;
    }

    if (!b.scheduledEvent.startDate) {
      return -1;
    }
    return (
      new Date(a.scheduledEvent.startDate) -
      new Date(b.scheduledEvent.startDate)
    );
  });

const NewPortal = () => {
  const {
    //authMode,
    authenticated,
    redirectToLanding,
    seriesList,
    seriesListLoading,
    brandKit,
    workspaceId,
    workspacePlan,
    currentWorkspaceName,
    hostName,
    isTrial,
    plan,
    show,
    workspacePlanValidUntil,
    currentSubscriptionRenewalTime,
    showPrivacyPolicyModal,
    setShowPrivacyPolicyModal,
    creatorUserName,
    creatorUserId,
    isWorkspaceOnboardingRequired,
  } = usePortal();

  const history = useHistory();

  const { name } = show || {};

  const [isNewEventModalOpen, setIsNewEventModalOpen] = useState(false);

  const [showDesktopOnlyModal, setShowDesktopOnlyModal] = useState(false);

  const getDesktopOnlyModalMessage = () => {
    switch (showDesktopOnlyModal) {
      case 'eventPage': {
        return 'The event page is only accessible from a desktop device.';
      }
      case 'eventCreation':
      default: {
        return 'New event creation can only happen from a desktop device.';
      }
    }
  };

  useSelectDefaultWorkspace(workspaceId);

  const showName = show?.name;
  useEffect(() => {
    document.title = `Zync ${showName || currentWorkspaceName || ''}`;
    return () => {
      document.title = `Zync`;
    };
  }, [showName, currentWorkspaceName]);

  useEffect(() => {
    mixpanel.track('Workspace Portal - Page Visit');
  }, []);

  useEffect(() => {
    if (seriesList.length === 0 || seriesListLoading || !creatorUserId) return;
    const completedSeriesList = seriesList.filter(
      (s) => checkIsCompletedLater(s) || checkIsCompletedRecently(s)
    );
    if (completedSeriesList.length >= 3) {
      updateCustomerEngagementStagesApi(
        creatorUserId,
        zyncCustomerEngagementStages.COMPLETED_3X_SESSIONS.stage
      );
    }
  }, [seriesListLoading, seriesList, creatorUserId]);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const templateKey = urlSearchParams.get('templateKey');
  const invite = urlSearchParams.get('invite');

  if (workspacePlan === PLANS.solo.id) {
    return <Redirect to={'/solo?workspaceId=' + workspaceId} />;
  }

  if (isWorkspaceOnboardingRequired) {
    return (
      <Redirect
        to={`/solostudiowelcome?templateKey=${templateKey}${
          invite === 'true' ? '&invite=true' : ''
        }&workspaceId=${workspaceId}`}
      />
    );
  }

  if (redirectToLanding) {
    return <Redirect to={getHomeUrl()} />;
  }

  const filteredSeriesList = seriesList.reduce(
    (filteredList, episode) => {
      switch (true) {
        case checkIsSoloEpisode(episode): {
          filteredList.soloEpisodes.push(episode);
          break;
        }
        case checkIsCompletedRecently(episode): {
          filteredList.recentlyCompletedEpisodes.push(episode);
          break;
        }

        case checkIsCompletedLater(episode): {
          filteredList.completedEpisodes.push(episode);
          break;
        }

        case checkIsUpcoming(episode): {
          filteredList.upcomingEpisodes.push(episode);
          break;
        }

        case checkIsIncomplete(episode): {
          filteredList.incompleteEpisodes.push(episode);
          break;
        }

        default: {
          console.warn('We could not assign this episode', episode);
          break;
        }
      }

      return filteredList;
    },
    {
      recentlyCompletedEpisodes: [],
      completedEpisodes: [],
      incompleteEpisodes: [],
      upcomingEpisodes: [],
      soloEpisodes: [],
    }
  );

  const {
    recentlyCompletedEpisodes,
    upcomingEpisodes: unsortedUpcomingEpisodes,
    incompleteEpisodes,
    completedEpisodes,
  } = filteredSeriesList;

  const upcomingEpisodes = sortByStartDate(unsortedUpcomingEpisodes);
  const completedEpisodesCount =
    recentlyCompletedEpisodes.length + completedEpisodes.length;

  return (
    <>
      {showPrivacyPolicyModal && (
        <PrivacyPolicyConsentModal
          setShowPrivacyPolicyModal={setShowPrivacyPolicyModal}
        />
      )}
      {showDesktopOnlyModal && (
        <DesktopOnlyModal
          message={getDesktopOnlyModalMessage()}
          onClose={() => setShowDesktopOnlyModal(false)}
        />
      )}
      <FuturisticBackground
        showDecorations={false}
        showGradient={false}
        showIsometricLinesDecoration={false}
        topGradientOpacity={FuturisticBackground.topGradientOpacity.LOW}
      />
      <HomeNavbar
        isAuthenticated={authenticated}
        workspaceId={workspaceId}
        isTrial={isTrial}
        plan={plan}
        trialUntil={workspacePlanValidUntil}
        workspacePlanValidUntil={workspacePlanValidUntil}
        currentSubscriptionRenewalTime={currentSubscriptionRenewalTime}
      />
      {seriesListLoading && !brandKit?.primaryColor ? (
        <div className="h-[90vh] grid place-content-center">
          <LogoSpinnerFullCentered />
        </div>
      ) : (
        <div>
          <div className="relative">
            <Container>
              {name && (
                <div
                  className="relative isolate min-h-[250px] flex flex-col justify-center rounded-[25px] overflow-hidden pb-12 shadow-800"
                  style={{
                    backgroundImage: `linear-gradient(${lightenOrDarkenColor(
                      brandKit.primaryColor,
                      20
                    )} 1px, transparent 1px), linear-gradient(to right, ${lightenOrDarkenColor(
                      brandKit.primaryColor,
                      20
                    )} 1px, ${brandKit.primaryColor} 1px)`,
                    backgroundSize: '40px 40px',
                    backgroundPositionY: '20px',
                  }}
                >
                  <div className="absolute right-4 top-4 z-10">
                    <Link
                      to={`/workspace/settings/?workspaceId=${workspaceId}`}
                    >
                      <span className="sr-only">
                        Click to open workspace settings
                      </span>
                      <SettingsIcon
                        className="fill-white"
                        style={{
                          fill: brandKit.accentContrast,
                        }}
                      />{' '}
                    </Link>
                  </div>
                  <h1
                    className="font-jakarta font-bold z-10 text-[28px] md:text-[48px] leading-[110%] md:max-w-[70%] md:ml-14 text-center md:text-left line-clamp-2 mb-1"
                    style={{
                      color: brandKit.primaryContrast,
                    }}
                  >
                    {name}
                  </h1>
                  {hostName && (
                    <h2
                      className="font-inter text-2xl font-bold md:ml-14"
                      style={{
                        color: brandKit.primaryContrast,
                      }}
                    >
                      {getHostedByText(show.hostedByText, creatorUserName)}
                    </h2>
                  )}
                  <div
                    className="absolute h-[75%] aspect-square rounded-full -right-10 top-[-10%]"
                    style={{
                      backgroundColor: brandKit.accentColor,
                    }}
                  />
                  <div
                    className="absolute h-[30%] aspect-square rounded-full right-28 top-[60%]"
                    style={{
                      backgroundColor: brandKit.accentColor,
                    }}
                  />
                  <div
                    className="absolute h-[60%] aspect-square rounded-full -right-8 top-[75%]"
                    style={{
                      backgroundColor: brandKit.accentColor,
                    }}
                  />
                  {isMobile ? (
                    <div className="h-[40px] absolute self-center md:self-start bottom-4 flex mx-auto md:ml-20 md:mr-0 bg-white text-[#696F8C] font-medium w-fit rounded-full border border-[#D0D5DD]">
                      <button
                        onClick={() => history.push(`${workspaceId}/feed`)}
                        className="flex gap-2 items-center px-2 py-1 whitespace-nowrap text-sm"
                      >
                        <MovieFilmIcon
                          width="30px"
                          height="30px"
                          color="#696F8C"
                        />
                        Highlight Reel
                      </button>
                      <div className="h-full w-[1px] bg-[#D0D5DD] mx-1" />
                      <button
                        title={
                          completedEpisodesCount
                            ? undefined
                            : 'Please complete one episode to see your show page'
                        }
                        disabled={!completedEpisodesCount}
                        className={`flex gap-2 text-sm items-center px-2 py-1 whitespace-nowrap ${
                          !completedEpisodesCount ? 'opacity-50' : ''
                        }`}
                        onClick={() => history.push(`/${workspaceId}/show`)}
                      >
                        <ShutterIcon />
                        Show Page
                      </button>
                    </div>
                  ) : (
                    <div className="absolute self-center md:self-start bottom-8 flex mx-auto md:ml-14 md:mr-0 bg-white text-[#696F8C] font-medium w-fit rounded-full border border-[#D0D5DD]">
                      <button
                        onClick={() => history.push(`${workspaceId}/feed`)}
                        className="flex gap-2 items-center px-2 py-1"
                      >
                        <MovieFilmIcon
                          width="20px"
                          height="20px"
                          color="#696F8C"
                        />
                        Highlight Reel
                      </button>
                      <div className="h-[32px] w-[1px] bg-[#D0D5DD] mx-2" />
                      <button
                        title={
                          completedEpisodesCount
                            ? undefined
                            : 'Please complete one episode to see your show page'
                        }
                        disabled={!completedEpisodesCount}
                        onClick={() => history.push(`/${workspaceId}/show`)}
                        className={`flex gap-2 items-center px-2 py-1 ${
                          !completedEpisodesCount ? 'opacity-50' : ''
                        }`}
                      >
                        <ShutterIcon
                          width="20px"
                          height="20px"
                          color="#696F8C"
                        />
                        Show Page
                      </button>
                      <div className="h-[32px] w-[1px] bg-[#D0D5DD] mx-2" />
                      <button
                        onClick={() =>
                          history.push(`/speakers?workspaceId=${workspaceId}`)
                        }
                        className="flex gap-2 items-center px-2 py-1"
                      >
                        <TwoPersonPlusIcon
                          width="20px"
                          height="20px"
                          color="#696F8C"
                        />
                        Manage Speakers
                      </button>
                    </div>
                  )}
                </div>
              )}
              <Notifications
                render={[
                  {
                    component: (
                      <Notification
                        key={1}
                        title={
                          <Link to="/workspace/settings">
                            SETUP YOUR BRAND KIT
                          </Link>
                        }
                        message={`You will have to setup your brand kit before running your first episode.${
                          isDefaultBrandKit(brandKit)
                            ? ' You are now using default Zync brand kit settings'
                            : ''
                        }`}
                      />
                    ),
                    when: isDefaultBrandKit(brandKit),
                  },
                ]}
              />
              <div className="flex justify-end items-center mb-4 gap-4">
                <div className="flex gap-2">
                  <Button
                    padding={Button.padding.MEDIUM}
                    color={Button.colors.PURPLE}
                    onClick={() =>
                      isMobile
                        ? setShowDesktopOnlyModal('eventCreation')
                        : setIsNewEventModalOpen(true)
                    }
                  >
                    <span className="text-sm">+ New Episode</span>
                  </Button>
                </div>
              </div>
              {recentlyCompletedEpisodes.length ? (
                <div className="mb-4">
                  <SectionHeader text="Recently Completed Episodes" />
                  <div className="flex flex-col gap-5">
                    {recentlyCompletedEpisodes.map((series) => (
                      <EventCard
                        key={series.meetingSeriesId}
                        series={series}
                        completed
                        setShowDesktopOnlyModal={setShowDesktopOnlyModal}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
              {upcomingEpisodes.length ? (
                <div>
                  <SectionHeader text="Upcoming Episodes" />
                  <div className="space-y-4">
                    {upcomingEpisodes.map((series) => (
                      <EventCard
                        key={series.meetingSeriesId}
                        series={series}
                        setShowDesktopOnlyModal={setShowDesktopOnlyModal}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div className="bg-purple/5 px-4 py-6 rounded-xl">
                  <SectionHeader text="Upcoming Episodes" />
                  <h2 className="text-center text-blue-dark font-medium mb-2">
                    You have no upcoming episodes
                  </h2>
                  <div className="flex justify-center">
                    <Button
                      padding={Button.padding.MEDIUM}
                      color={Button.colors.PURPLE}
                      onClick={() =>
                        isMobile
                          ? setShowDesktopOnlyModal('eventCreation')
                          : setIsNewEventModalOpen(true)
                      }
                    >
                      <span className="text-base">+ New Episode</span>
                    </Button>
                  </div>
                </div>
              )}
              {incompleteEpisodes.length ? (
                <div>
                  <SectionHeader text="Incomplete Episodes" />
                  <div className="flex flex-col gap-5">
                    {incompleteEpisodes.map((event) => (
                      <EventCard
                        key={event.meetingSeriesId}
                        series={event}
                        setShowDesktopOnlyModal={setShowDesktopOnlyModal}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
              {completedEpisodes.length ? (
                <SectionAccordion text="Completed Episodes">
                  <div className="flex flex-col gap-5">
                    {completedEpisodes.map((event) => (
                      <EventCard
                        key={event.meetingSeriesId}
                        series={event}
                        completed
                        setShowDesktopOnlyModal={setShowDesktopOnlyModal}
                      />
                    ))}
                  </div>
                </SectionAccordion>
              ) : null}
            </Container>
          </div>
        </div>
      )}
      {isNewEventModalOpen && (
        <CreateNewEventModal
          onClose={() => setIsNewEventModalOpen(false)}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={true}
          episodeType={episodeTypes.default}
        />
      )}
      <IsometricShapesDecoration className="absolute left-0 w-full object-cover" />
    </>
  );
};

export const EventLink = ({
  children,
  event: {
    meetingSeriesId,
    title,
    autoLaunchConfig: { templateKey },
    workspace,
  },
  setShowDesktopOnlyModal,
  isContentKitCompleted,
}) => {
  const handleOnClick = (e) => {
    if (isMobile) {
      e.preventDefault();
      setShowDesktopOnlyModal('eventPage');
    }
  };
  return (
    <Link
      className="underline flex gap-3.5"
      to={
        isContentKitCompleted
          ? `/e/${meetingSeriesId}/details/publish?`
          : `/e/${meetingSeriesId}/details?workspaceId=${workspace?.workspaceId}`
      }
      onClick={handleOnClick}
      style={{ wordBreak: 'break-word' }}
    >
      {children ? children : getMeetingTitle(title)}
    </Link>
  );
};

const Container = React.memo(({ children }) => (
  <div className="flex flex-col overflow-hidden p-4 md:p-12 gap-6 max-w-[1080px] mx-auto">
    {children}
  </div>
));

const SectionHeader = React.memo(({ text }) => (
  <h2 className="text-xl font-semibold mb-5">{text}</h2>
));

const SectionAccordion = ({ children, text }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <div className="relative">
      <button
        onClick={() => setIsCollapsed((wasCollapsed) => !wasCollapsed)}
        className={classNames(
          'w-3 absolute right-4 top-2 flex justify-center items-center',
          isCollapsed ? 'rotate-90' : 'rotate-[270deg]'
        )}
      >
        <AngleIcon width="18px" className="fill-blue-gray" />
      </button>
      <SectionHeader text={text} />
      {isCollapsed && children}
    </div>
  );
};

export default NewPortal;
