import { Modal, ModalBody, ModalWindow } from '../../components/Modal';
import { useState } from 'react';
import { StarIcon } from '../../components/icons/StarsIcon';
import { CheckInCircleIcon } from '../../components/icons/CheckInCircleIcon';
import classNames from '../../helper/classNames';
import { Button } from '../../components/common/Button';
import { soloSessionCreationKeys } from './constants';
import { CameraIcon } from '../../components/icons/CameraIcon';
import { DocumentIcon } from '../../components/icons/DocumentIcon';
import { ListIcon } from '../../components/icons/ListIcon';
import { Link } from 'react-router-dom';
import { WarningCircleIcon } from '../../components/icons/WarningCircleIcon';

export const soloSessionCreationOptions = [
  {
    itemKey: soloSessionCreationKeys?.idea,
    title: (
      <p>
        <span>Turn Idea to Video</span>
        <span className="font-light text-[13px] text-[#696F8C] text-sm block">
          From simple idea to video script.
        </span>
      </p>
    ),
    aiSuggest: true,
    disabled: false,
    icon: <CameraIcon width="18px" height="18px" className="fill-white" />,
    header: 'Turn Idea to Video',
    description: 'From simple idea to video script.',
  },
  {
    itemKey: soloSessionCreationKeys?.blogPost,
    title: (
      <p>
        <span>Convert Existing Content</span>
        <span className="font-light text-[13px] text-[#696F8C] text-sm block">
          From a blog, LinkedIn post, or newsletter.
        </span>
      </p>
    ),
    aiSuggest: true,
    disabled: false,
    icon: <DocumentIcon width="18px" height="18px" className="fill-white" />,
    header: 'Convert Existing Content',
    description: 'From a blog, LinkedIn post, or newsletter.',
  },
  {
    itemKey: soloSessionCreationKeys?.manual,
    title: (
      <p>
        <span>Answer Audience Questions</span>
        <span className="font-light text-[13px] text-[#696F8C] text-sm block">
          Respond to key questions from your audience
        </span>
      </p>
    ),
    aiSuggest: false,
    disabled: false,
    icon: <ListIcon />,
    header: 'Answer Audience Questions',
    description: 'Respond to key questions from your audience',
  },
];

const SoloSessionCreationOption = ({
  itemKey,
  selectedKey,
  setSelectedKey,
  disabled,
  title,
  icon,
  aiSuggest,
}) => {
  const isSelected = itemKey === selectedKey;
  return (
    <button
      title="Click to select this option"
      className={classNames(
        'border rounded-md p-2 flex justify-between items-center',
        isSelected
          ? 'border-gray/75 shadow-lg bg-purple/5'
          : 'border-gray/50 shadow-sm',
        disabled ? 'pointer-events-none opacity-50' : ''
      )}
      onClick={() => {
        setSelectedKey((key) => (key === itemKey ? null : itemKey));
      }}
    >
      <div className="flex gap-2 items-center">
        <div className="w-8 h-8 rounded-full bg-purple grid place-content-center relative">
          <div
            className={classNames(
              'absolute right-0 top-0 translate-x-1/4 -translate-y-1/4',
              isSelected ? '' : 'invisible'
            )}
          >
            <CheckInCircleIcon color="#00A3A5" width={16} height={16} />
          </div>
          {icon}
        </div>
        <div className="flex justify-between items-center"></div>
        <div>
          <h3 className="font-medium text-sm text-left">{title}</h3>
        </div>
      </div>

      <div
        className={classNames(
          'flex gap-2 items-center text-blue-light font-medium text-xs',
          aiSuggest ? '' : 'invisible'
        )}
      >
        <StarIcon /> Ai Suggest
      </div>
    </button>
  );
};

export const NewSoloSessionSelectionModal = ({
  onClose,
  onConfirm,
  disabled: cannotProceed,
}) => {
  const [selectedKey, setSelectedKey] = useState(null);

  return (
    <ModalWindow
      size={Modal.size.lg}
      onClose={onClose}
      onOutsideClick={onClose}
    >
      <h2 className="font-base font-semibold text-base ml-8 mt-6">
        What would you like to do for your next studio session?
      </h2>
      <ModalBody>
        <div className="flex flex-col gap-4 -mt-4">
          {cannotProceed ? (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 bg-white w-[1/2] p-6 rounded-xl shadow-800">
              <p className="text-blue-dark text-lg text-center flex items-center justify-center gap-2 mb-2">
                <WarningCircleIcon /> No default speaker selected
              </p>
              <p className="text-blue-gray text-sm text-center">
                Go to{' '}
                <Link
                  to={`/workspace/${encodeURIComponent(
                    'solo studio settings'
                  )}`}
                  className="text-blue-light font-medium underline"
                >
                  Solo Studio Settings
                </Link>{' '}
                to enable this feature
              </p>
            </div>
          ) : null}
          {soloSessionCreationOptions.map(
            ({ disabled, aiSuggest, title, itemKey, icon }) => (
              <SoloSessionCreationOption
                key={itemKey}
                setSelectedKey={setSelectedKey}
                selectedKey={selectedKey}
                aiSuggest={aiSuggest}
                itemKey={itemKey}
                title={title}
                disabled={cannotProceed || disabled}
                icon={icon}
              />
            )
          )}
        </div>
        <div className="flex justify-between items-center mt-8 gap-4">
          <Button
            color={cannotProceed ? Button.colors.PURPLE : Button.colors.WHITE}
            onClick={onClose}
            size={Button.sizes.FULL}
          >
            <span className="font-medium text-sm">Cancel</span>
          </Button>
          {cannotProceed ? null : (
            <Button
              color={Button.colors.PURPLE}
              onClick={() => {
                onConfirm(selectedKey);
                onClose();
              }}
              size={Button.sizes.FULL}
              state={
                selectedKey ? Button.states.DEFAULT : Button.states.DISABLED
              }
            >
              <span className="font-medium text-sm">OK</span>
            </Button>
          )}
        </div>
      </ModalBody>
    </ModalWindow>
  );
};
