import { presenterTypes } from '../components/series/settings/EventPresenters';
import moment from 'moment/moment';
import { getLocalTimezoneAreaName, getOneHourFromDate } from './rsvp';
import { getContrastColor } from 'zync-common/helper/color';
import { defaultBrandkitCombinations } from 'zync-common/helper/brandkitColorCombinations';

const searchParams = new URLSearchParams(window.location.search);
export const fromZyncBottomsup =
  searchParams.get('fromZyncBottomsup') === 'true';

export const plans = {
  FREE: 'free',
  TEAM: 'team',
  EVENT: 'event',
};

export const demoHostUser = 'zyncdemobot@zync.ai';

export const rsvpFormFields = {
  ORGANIZATION: 'organization',
  JOB_TITLE: 'jobTitle',
  PHONE: 'phone',
};

export const demoUsers = {
  robertBrink: {
    userId: 'robertbrink@zync.ai',
    roles: ['guest', 'presenter'],
    userName: 'Robert DemoUser',
    fakeVideoStream:
      'https://storage.googleapis.com/zync-media/media/videos/fake-users/caucasian_older_male.mp4',
  },
  aadeshArmin: {
    userId: 'aadesharmin@zync.ai',
    roles: ['guest', 'vip'],
    userName: 'Aadesh DemoBot',
    fakeVideoStream:
      'https://storage.googleapis.com/zync-media/media/videos/fake-users/asian_male.mp4',
  },
  lokLin: {
    userId: 'loklin@zync.ai',
    roles: ['guest', 'panelist'],
    userName: 'Lok DemoRobot',
    fakeVideoStream:
      'https://storage.googleapis.com/zync-media/media/videos/fake-users/hispanic_female.mp4',
  },
  odetteMason: {
    userId: 'odettemasson@zync.ai',
    roles: ['guest', 'panelist'],
    userName: 'Odette NotARealPerson',
    fakeVideoStream:
      'https://storage.googleapis.com/zync-media/media/videos/fake-users/european_redhead.mp4',
  },
  carmineNapolitano: {
    userId: 'carminenapolitano@zync.ai',
    roles: ['guest', 'panelist'],
    userName: 'Carmine BotUser',
    fakeVideoStream:
      'https://storage.googleapis.com/zync-media/media/videos/fake-users/africanamerican_female.mp4',
  },
};
export const TROUBLESHOOTING_URL = 'https://www.zync.ai/troubleshooting-guide';
export const SLIDE_CONTAINER_ID = 'slide-container';
export const videoQualities = {
  HIGH: 'HIGH',
  MED: 'MED',
  LOW: 'LOW',
};

export const TOTAL_MARGIN_LIVE_MEETING_NAVIGATION_ARROWS = 120;

export const DEFAULT_DESCRIPTION =
  "‌Join us for an exciting virtual event that you won't want to miss! To participate, simply register and you'll receive an email with instructions on how to join the event. We can't wait to connect with you online!";

export const taskStatuses = {
  skipped: 'skipped',
  completed: 'completed',
};

const getRandomColorCombination = () => {
  const randomIndex = Math.floor(
    Math.random() * defaultBrandkitCombinations.length
  );
  return defaultBrandkitCombinations[randomIndex];
};

const randomColorCombination = getRandomColorCombination();

export const defaultBrandkit = {
  brandKit: {
    font: 'Poppins',
    primaryColor: randomColorCombination[0],
    accentColor: randomColorCombination[1],
    backgroundColor: '#FFFFFF',
    primaryContrast: getContrastColor(randomColorCombination[0]),
    accentContrast: getContrastColor(randomColorCombination[1]),
    logoUrl:
      'https://storage.googleapis.com/zync-media/assets/static/default_blank_logo.png',
    ctaText: 'Powered by Zync',
  },
};

export const getScheduledEventOneWeekFromNow = () => {
  const now = moment();
  const oneWeekFromNow = now.add(1, 'week').set('hour', 14).set('minute', 0);
  const startDate = oneWeekFromNow.toISOString();
  const endDate = getOneHourFromDate(startDate);
  const timezone = getLocalTimezoneAreaName();

  return {
    startDate,
    endDate,
    timezone,
  };
};

export const DEFAULT_POSTER_PLACEHOLDERS = {
  title: 'Exploring the world of Generative AI - with Sam Altman',
  scheduledEvent: getScheduledEventOneWeekFromNow(),
  settings: {
    eventPresenters: [
      {
        emailAddress: '',
        fullName: 'Sam Altman',
        jobTitle: 'Chief Marketing Officer at Acme Inc.',
        linkedInUrl: '',
        presenterPictureUrl:
          'https://storage.googleapis.com/zync-media/assets/static/fake-user1.PNG',
        type: presenterTypes.SPEAKER,
      },
    ],
  },
};

export const CONTENT_KIT_ASSET_BASE_URL = process.env
  .REACT_APP_CONTENT_KIT_DOMAIN
  ? `${process.env.REACT_APP_CONTENT_KIT_DOMAIN}/api/asset`
  : 'http://localhost:1230/api/asset';

export const CONTENT_KIT_BASE_URL = process.env.REACT_APP_CONTENT_KIT_DOMAIN
  ? `${process.env.REACT_APP_CONTENT_KIT_DOMAIN}/api/contentkit`
  : 'http://localhost:1230/api/contentkit';

export const PROCESSOR_BASE_URL = process.env.REACT_APP_CONTENT_KIT_DOMAIN
  ? `${process.env.REACT_APP_CONTENT_KIT_DOMAIN}/api/processor`
  : 'http://localhost:1230/api/processor';

export const PROMOTION_KIT_BASE_URL = process.env.REACT_APP_CONTENT_KIT_DOMAIN
  ? `${process.env.REACT_APP_CONTENT_KIT_DOMAIN}/api/promotionkit`
  : 'http://localhost:1230/api/promotionkit';

export const PREPARATION_KIT_BASE_URL = process.env.REACT_APP_CONTENT_KIT_DOMAIN
  ? `${process.env.REACT_APP_CONTENT_KIT_DOMAIN}/api/preparation-kit`
  : 'http://localhost:1230/api/preparation-kit';

export const isTest = () => {
  const currentURL = window.location.href;
  if (currentURL.includes('test.zync.ai')) {
    return true;
  } else {
    return false;
  }
};

export const INTRO_SETTINGS = {
  //default: 'Default Intro',
  custom: 'Custom Intro',
  none: 'No Intro',
};

export const OUTRO_SETTINGS = {
  default: 'Default Outro',
  custom: 'Custom Outro',
  none: 'No Outro',
};

export const HOST_IMAGE_SETTINGS = {
  none: 'No picture',
  default: 'Default picture',
  custom: 'Custom picture',
};

export const CAPTIONS_CONFIG_SETTINGS = [
  { key: 'default', title: 'Default - Randomly picked' },
  { key: 'scene_caption_style_1', title: 'Style 1' },
  { key: 'scene_caption_style_2', title: 'Style 2' },
  { key: 'scene_caption_style_3', title: 'Style 3' },
];

export const LAYOUT_CONFIG_SETTINGS = [
  { key: 'default', title: 'Default - Randomly picked' },
  { key: 'scene_layout_1', title: 'Style 1' },
  { key: 'scene_layout_2', title: 'Style 2' },
  { key: 'scene_layout_3', title: 'Style 3' },
];

export const MUSIC_CONFIG_SETTINGS = [
  { key: 'default', title: 'Default - Randomly picked' },
  { key: 'no_music', title: 'No background music' },
];

export const VIDEO_THEME_SETTINGS = [
  { key: 'default', title: 'Default' },
  { key: 'sports', title: 'Sports' },
];

export const BROLL_CONFIG_SETTINGS = [
  { key: 'false', title: 'No' },
  { key: 'true', title: 'Yes' },
];

export const NEW_SESSION_EMAIL_CONFIG_SETTINGS = [
  { key: 'false', title: 'No' },
  { key: 'true', title: 'Yes' },
];

export const EMAIL_REMINDER_24H_CONFIG_SETTINGS = [
  { key: 'false', title: 'No' },
  { key: 'true', title: 'Yes' },
];

export const THUMBNAIL_CONFIG_SETTINGS = [
  { key: 'default', title: 'Default - Randomly picked' },
  { key: 'thumbnail_solo_segment_1', title: 'Style 1' },
  { key: 'thumbnail_solo_segment_2', title: 'Style 2' },
  { key: 'thumbnail_solo_segment_3', title: 'Style 3' },
];

export const INTRO_CONFIG_SETTINGS = [
  { key: 'no_intro', title: 'No intro' },
  { key: 'intro_style_1', title: 'Basic Intro' },
];

export const timelineStyleKeys = {
  basic: 'basic',
  discussion_points: 'discussion_points',
  audience_interaction: 'audience_interaction',
};

export const DEFAULT_TIMELINE_SETTINGS = [
  { key: timelineStyleKeys.basic, title: 'Basic' },
  { key: timelineStyleKeys.discussion_points, title: 'Discussion Points' },
  {
    key: timelineStyleKeys.audience_interaction,
    title: 'Audience Interaction',
  },
];
