import React, { useState, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import Lottie from 'react-lottie';
import moment from 'moment/moment';
import { useDeepCompareMemo } from 'use-deep-compare';
import { soundsMap } from '../blocks/SoundBoardBlock';
import { SubscriptionActionsContextProvider } from '../components/planEnforcement/context';
import { useWorkspacePlanEnforcementMessages } from '../components/useWorkspacePlanEnforcementMessages';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { useDailyMeeting } from '../hooks/useDailyMeeting';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { io } from 'socket.io-client';
import { LiveMeeting } from '../components/LiveMeeting';
import { isDev, isMobile } from '../helper';
import {
  sendEvent,
  fetchSeriesApi,
  fetchMeetingApi,
  setSocket,
} from '../helper/api';
import { ZyncLoadingModal } from '../components/ZyncLoadingModal';
import { DailyMediaSettings } from '../components/DailyMediaSettings';
import { demoUsers, fromZyncBottomsup } from '../helper/constants';
import { logerror, loginfo, logwarn } from '../helper/contextualLogger';
import {
  isRolesAGuest,
  isRolesMeetingController,
  Roles,
} from '../helper/roles';
import { useDailyControls } from '../hooks/useDailyControls';
import { useMeetingControls } from '../hooks/useMeetingControls';
import { ToastOutlet } from '../components/ToastOutlet';
import mixpanel from 'mixpanel-browser';
import { isAuthorized, SeriesVisibility } from '../helper/visibility';
import { toast } from 'react-toastify';
import {
  CALL_STATE_INITIALIZING,
  CALL_STATE_READY,
} from '../hooks/dailyConstants';
import { ZyncErrorModal } from '../components/ZyncErrorModal';
import { useWorkspaceSubscription } from '../hooks/useWorkspaceSubscription';
//import { MeetingFullErrorPage } from './MeetingFullErrorPage';
import {
  ScreenshareHelpToast,
  screenshareToastOptions,
} from '../components/ScreenshareHelpToast';
import { useDailyOnParticipantsChange } from '../hooks/useDailyOnParticipantsChange';
import { Modal, ModalBody, ModalWindow } from '../components/Modal';
import { AnimatedReactions } from '../components/Reactions';
import { reactionsCache } from '../helper/InMemoryCache';
import { useMeeting } from '../hooks/useMeeting';
import { Button } from '../components/common/Button';
import classNames from '../helper/classNames';
import { FuturisticBackground } from '../components/FuturisticBackground';
import fiveDotsLoading from '../components/assets/lottie/fiveDotsLoading.json';
import { presenterTypes } from '../components/series/settings/EventPresenters';
import { LiveMeetingPlayer } from '../components/mobile/LiveMeetingPlayer';
import { notifyUser } from '../components/authoring/hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MeetingWaitingScreen } from '../components/MeetingWaitingScreen';
import { episodeTypes } from 'zync-common/types';
import { getSpeakerInviteUrl } from './EventLanding/Plan/PlanInviteSpeakerCard';
import ChunkedLocalRecordingContextProvider from '../components/Meeting/context/ChunkedLocalRecordingContextProvider';
import { NavigationConfirmation } from '../components/NavigationConfirmation';
export const getUserAudioElementsAndPlay = ({ onSuccess, onError }) => {
  const userAudioElements = document.querySelectorAll('.user-audio');

  if (!userAudioElements.length) {
    if (onSuccess) {
      return onSuccess();
    }
  }

  userAudioElements.forEach(async (element) => {
    if (element.paused) {
      try {
        await element.play();
        if (onSuccess) {
          return onSuccess();
        }
      } catch (e) {
        if (onError) {
          onError();
        }
      }
    }
  });
};

const DISCONNECT_TOAST_ID = 'disconnectToast';

const errorModeratorJoiningOnMobileSrc = getStaticAssetUrl(
  'error-moderator-joining-on-mobile.svg'
);
const errorNonModeratorJoiningOnMobileSrc = getStaticAssetUrl(
  'mobile-device-unsupported.png'
);

const MS_UNTIL_JOIN_CONSIDERED_SLOW = 10_000;

const MAX_JOIN_RETRIES = 5;

const joinRetryPhrases = [
  'once',
  'twice',
  'thrice',
  'the fourth time',
  'one last time',
];

const RetryPhrase = ({ number }) => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);

    const fadeInTimeout = setTimeout(() => {
      setFadeIn(false);
    }, 4500);

    return () => {
      clearTimeout(fadeInTimeout);
    };
  }, [number]);

  return (
    <div className={classNames(fadeIn && 'animate-[fadeIn_1000ms]')}>
      {joinRetryPhrases[number]}
    </div>
  );
};

function onSoundEffect(action) {
  const effect = action?.data?.sound;

  if (effect) {
    soundsMap[effect].effect.play({ outputDeviceId: '' });
  }
}

function onUserReaction(action) {
  const emoji = action.data.reaction;

  reactionsCache.push({ emoji });

  ReactDOM.render(
    <div className="fixed z-[100] top-0 w-full">
      {reactionsCache.get().map(({ emoji, expiry }) => (
        <AnimatedReactions emoji={emoji} key={expiry} />
      ))}
    </div>,
    document.getElementById('reactions-root')
  );
}

async function onRecordingInterrupted(action, dispatch) {
  dispatch({
    type: 'UI_RECORDING_STOPPED',
  });
  notifyUser('The recording was interrupted. Please try again.');
}

const eventHandlersKey = {
  SOUND_EFFECT: 'soundEffect',
  REACTION: 'reaction',
  RECORDING_INTERRUPTED: 'RECORDING_INTERRUPTED',
};

const eventHandlers = {
  [eventHandlersKey.SOUND_EFFECT]: onSoundEffect,
  [eventHandlersKey.REACTION]: onUserReaction,
  [eventHandlersKey.RECORDING_INTERRUPTED]: onRecordingInterrupted,
};

/* This is the old getFakeSpeakers helper based on bots - left as per Trello card request https://trello.com/c/NtaL3C6t/3691-block-single-video-panelist-use-speaker-images
const getFakeSpeakers = (speakers) => {
  const demoUsersArray = Object.values(demoUsers);
  const fakeSpeakers = [];
  let demoUserArrayIndex = 0;
  let counter = 0;
  for (let i = 0; i < speakers.length; i++) {
    const userName = `BOT_${speakers[i]?.fullName || 'N/A'}`;
    const roles = [`speaker${counter + 1}`, 'speaker'];
    const userId = `${demoUsersArray[demoUserArrayIndex].userId}${counter}`;
    fakeSpeakers.push({
      ...demoUsersArray[demoUserArrayIndex],
      userId,
      userName,
      roles,
    });
    demoUserArrayIndex + 1 === demoUsersArray.length
      ? (demoUserArrayIndex = 0)
      : (demoUserArrayIndex += 1);
    counter += 1;
  }
  fakeSpeakers.push({
    ...demoUsersArray[demoUserArrayIndex],
    userName: 'BOT_GUEST',
    roles: ['guest'],
  });
  return fakeSpeakers;
};
*/

const getFakeSpeakers = (speakers) => {
  return speakers.map((speaker, i) => ({
    avatarUrl: speaker.presenterPictureUrl,
    userId: `BOT_${speaker.emailAddress || speaker.fullName}`,
    roles: ['guest', 'presenter', 'speaker', 'speaker' + (i + 1)],
    userName: speaker.fullName,
  }));
};

const Meeting = ({ location, series, setSeries }) => {
  const [
    joinMeetingAsNonModeratorOnMobile,
    setJoinMeetingAsNonModeratorOnMobile,
  ] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // Handle URL params.
  const { meetingSeriesId } = useParams();

  const {
    autoLaunchConfig: { templateKey },
  } = series;

  const params = new URLSearchParams(location.search);
  const previewSceneId = params.get('sceneId') || undefined;
  const isPreview = params.get('preview') != null;
  const disableBots = params.get('enableBots') === 'false';

  // Retrieve data from Redux.
  const { user: registeredUser } = useSelector((st) => st.auth);
  const { leftMeeting } = useSelector((state) => state.clientDetails);
  const meetingLeaderUserId = useSelector(
    (state) => state.meetingState.meetingLeaderUserId
  );
  const { avatarUrl, userId, userName, emailAddress, invisible, registered } =
    registeredUser || {};

  // 1) Initial page load actions.
  //
  //  * Fetch the meeting series from the API.
  //  * Fetch the workspace subscription and current meeting plan
  //  * Update the page title.
  //  * Extract data from the meeting series (stored in local state.)
  //  * Exract data from the meeting (stored in redux.)

  const { subscription, refreshSubscription } = useWorkspaceSubscription(
    registered ? series?.workspace?.stripeSubscriptionId : undefined // non-registered users do not need to fetch subscription details
  );

  const {
    completed,
    running,
    isDemo,
    hasFakeUsers,
    meetingConfig,
    meetingId,
    invisibleUsers,
    users,
    wentLive,
  } = useMeeting();

  const user = useMemo(() => {
    if (invisible) {
      return (invisibleUsers || {})[userId];
    } else {
      return (users || {})[userId];
    }
  }, [invisibleUsers, invisible, users, userId]);

  const {
    dispatchMeetingFullEnforcementToast,
    dispatchMeetingEndingSoonEnforcementToast,
  } = useWorkspacePlanEnforcementMessages({ series, user, subscription });

  const userRoles = user?.roles ?? [];
  const { joined, forceKicked = false } = user || {};

  // Extract data from the current meeting series.
  const {
    attendees,
    autoLaunchConfig,
    scheduledEvent,
    visibility,
    workspace,
    settings,
  } = series || {};
  const { registeredAttendees } = scheduledEvent || {};
  const { workspaceId } = workspace || {};
  const { allowJoinWithoutMedia, eventPresenters } = settings || {};

  const attendee = useMemo(() => {
    const foundAttendee = (attendees || []).find(
      (attendee) => attendee.emailAddress === userId
    );
    if (visibility === SeriesVisibility.RSVP) {
      const isModerator = isRolesMeetingController(foundAttendee?.roles);

      return isModerator
        ? foundAttendee
        : (registeredAttendees || []).find((a) => a.emailAddress === userId);
    } else {
      return foundAttendee;
    }
  }, [attendees, registeredAttendees, userId, visibility]);

  /**
   * WARNING: We grab fakeUsers from autoLaunchConfig which comes from the series object.
   * If in the future we can change this setting from authoring and someone changes it during a live meeting
   * because they had it open before it started, then those changes will affect whoever joins after.
   */
  const fakeUsers = useMemo(() => {
    if (isDemo || isPreview || hasFakeUsers) {
      if (
        eventPresenters.length > 0 &&
        eventPresenters.find((ep) => ep.type === presenterTypes.SPEAKER)
      ) {
        return getFakeSpeakers(
          eventPresenters.filter((ep) => ep.type === presenterTypes.SPEAKER)
        );
      }
      if (
        Array.isArray(autoLaunchConfig?.fakeUsers) &&
        autoLaunchConfig?.fakeUsers.length > 0
      ) {
        return autoLaunchConfig.fakeUsers;
      }
      return Object.values(demoUsers);
    }
    return [];
  }, [
    isDemo,
    isPreview,
    hasFakeUsers,
    eventPresenters,
    autoLaunchConfig.fakeUsers,
  ]);

  const meetingContainsAtLeastOneUser = useMemo(
    () => Object.values(users || []).some((user) => !user.isFake),
    [users]
  );

  const isHost = eventPresenters.find(
    (ep) => ep.type === presenterTypes.HOST && emailAddress === ep.emailAddress
  );

  const isSpeaker = eventPresenters.find(
    (ep) =>
      ep.type === presenterTypes.SPEAKER && emailAddress === ep.emailAddress
  );

  const roles = useDeepCompareMemo(() => {
    // Note: this refires any time that meeting state is refreshed, due to userRoles becoming
    // a new array reference with the same values. This doesn't seem to affect performance though.
    let roles = userRoles;
    if (roles.length === 0) {
      roles = attendee?.roles ?? [];
    }
    if (roles.length === 0) {
      roles = [
        (isDemo && !meetingContainsAtLeastOneUser
          ? 'moderator'
          : attendee?.role) || 'guest',
      ];
    }

    // Finally merge in the roles that we set for the registered user object from auth reducer.
    // This is where we currently get roles from url links
    (registeredUser.roles || []).forEach((r) => {
      if (!roles.includes(r)) {
        roles.push(r);
      }
    });

    if (roles.includes(presenterTypes.HOST) && !roles.includes('moderator')) {
      roles.push('moderator');
    }

    if (!roles.includes(presenterTypes.HOST) && isHost) {
      roles.push(presenterTypes.HOST);
    }

    if (
      series.settings.episodeType === episodeTypes.solo &&
      !roles.includes(Roles.MODERATOR) &&
      isSpeaker
    ) {
      roles.push(Roles.MODERATOR);
    }

    if (!roles.includes(presenterTypes.SPEAKER) && isSpeaker) {
      roles.push(presenterTypes.SPEAKER);
    }
    return roles;
  }, [isDemo, attendee, userRoles, registeredUser?.roles]);

  const isMeetingController = isRolesMeetingController(roles);
  const attendeeRoles = attendee?.roles;

  const guestsStartWithAudioOff = series?.settings.guestsStartWithAudioOff;
  const isUserAGuest =
    !attendeeRoles /* unregistered user has no roles */ ||
    isRolesAGuest(attendeeRoles);
  const startAudioOffOverride = guestsStartWithAudioOff && isUserAGuest;

  const subscriberOnly = useMemo(() => {
    return (
      isMobile ||
      registeredUser.recordingUser ||
      (allowJoinWithoutMedia && isUserAGuest && roles.length <= 1)
    );
  }, [
    allowJoinWithoutMedia,
    isUserAGuest,
    registeredUser.recordingUser,
    roles.length,
  ]);

  // One time fetch the meeting config and add it to the state after the meeting has been started
  // NOTE: Meeting Config will only get set after the meeting has started.
  useEffect(() => {
    if (!meetingId || !running || meetingConfig) return;
    const fetchMeetingConfig = async () => {
      const result = await fetchMeetingApi(meetingId);
      if (result.meetingConfig) {
        loginfo({
          message: `Fetch meetingConfig using meetingId: ${meetingId}.`,
        });
        dispatch({
          type: 'SET_MEETING_CONFIG',
          meetingConfig: result.meetingConfig,
        });
      }
    };
    fetchMeetingConfig();
  }, [meetingId, running, meetingConfig, dispatch]);

  // Set page title.

  useEffect(() => {
    document.title = '🔴 LIVE - zync - ' + userName || '';
    return () => {
      document.title = 'Zync';
    };
  });

  // 2) Authorization checks
  //
  //  * Check if the user is authorized. See isAuthorized for rules.

  const [authorized, setAuthorized] = useState(); // True if this step is complete.

  useEffect(() => {
    if (!series || !registeredUser) return;
    setAuthorized(isAuthorized(registeredUser, series));
  }, [series, registeredUser]);

  // 3) Begin initializing daily.co
  //
  //  * Create call object
  //  * Handled via daily.preAuth(...meetingId...)

  const { handleActiveSpeakerChange } = useMeetingControls();

  const { onParticipantsChange } = useDailyOnParticipantsChange(users);

  const [numberOfJoinRetries, setNumberOfJoinRetries] = useState(0);

  const [retryPrepareDaily, setRetryPrepareDaily] = useState(0);
  const retryPrepareDailyIntervalRef = useRef(null);

  useDailyMeeting({
    userId: authorized ? userId : undefined,
    meetingId: authorized ? meetingId : undefined,
    onActiveSpeakerChange: handleActiveSpeakerChange,
    onParticipantsChange,
    fakeUsers,
    onScreenShareError: (errorType) => {
      toast(<ScreenshareHelpToast />, screenshareToastOptions);
    },
    subscriberOnly,
    isMeetingController,
    meetingLeaderUserId,
    retryPrepareDaily,
    retryPrepareDailyIntervalRef,
    startAudioOffOverride,
  });

  // 4) Connect to the server websocket
  //const [isMeetingFull, setIsMeetingFull] = useState(false);
  const [meetingStateUpdated, setMeetingStateUpdated] = useState(false); // True if this step is complete.
  const [socketObject, setSocketObject] = useState(null);
  const subscribed = !!socketObject;

  // Connect / disconnect to socket.
  useEffect(() => {
    // Basic requirements for attempting to make a socket connection
    if (!authorized || !meetingId || !userId) return;
    loginfo({
      userId,
      meetingId,
      message: `User's device info: ${navigator.userAgent}`,
    });
    const socket = io('/', {
      closeOnBeforeunload: false,
      query: { userId, meetingId },
      pingTimeout: 60_000,
      pintInterval: 25_000,
      upgradeTimeout: 30_000,
    });

    setSocketObject(socket);
    setSocket(socket);

    socket.on('connect', () => {
      loginfo({
        userId,
        meetingId,
        message: `successfully connected on socket ${socket.id}`,
      });

      toast.dismiss(DISCONNECT_TOAST_ID);
    });
    let badNetworkNotificationTimer;
    socket.on('disconnect', (reason) => {
      if (
        reason === 'io server disconnect' ||
        reason === 'io client disconnect'
      ) {
        loginfo({
          message: `${userId} disconnected from websocket with a reason ${reason}`,
          userId,
          meetingId,
        });
        return;
      }
      retryPrepareDailyIntervalRef.current = setInterval(() => {
        setRetryPrepareDaily((prevState) => prevState + 1);
      }, 5000);
      // If this is a case where we will want to automatically attempt a reconnect. Set a timer to show a toast indicating
      // that there may be some issue with their internet
      badNetworkNotificationTimer = setTimeout(() => {
        if (socket.connected) return;
        logwarn({
          userId,
          message: `Observed websocket connectivity issues for userId: ${userId}`,
        });
        toast.warn(
          <div className="flex flex-col gap-2">
            <span>
              Uh oh, we are unable to connect to the internet. Please check your
              internet connection if this persists. Click here to reload the
              page{' '}
            </span>
            <Button
              color={Button.colors.PURPLE}
              padding={Button.padding.SMALL}
              onClick={() => window.location.reload()}
              size={Button.sizes.FULL}
            >
              <span className="text-sm">Reload</span>
            </Button>
          </div>,
          {
            autoClose: false,
            closeButton: () => null,
            position: toast.POSITION.BOTTOM_CENTER,
            closeOnClick: false,
            toastId: DISCONNECT_TOAST_ID,
          }
        );
      }, 5000);
    });
    // Explicitly disconnect when this component unloads
    return () => {
      if (badNetworkNotificationTimer) {
        clearTimeout(badNetworkNotificationTimer);
      }
      socket.disconnect();
      setSocketObject(null);
      clearInterval(retryPrepareDailyIntervalRef.current);
    };
  }, [dispatch, authorized, meetingId, userId]);

  // Add handlers.
  useEffect(() => {
    if (!socketObject) return;

    /**
     * Dispatches an action to the meetingState reducer
     * to update its state with the new meeting object.
     */
    function onMeetingState(event) {
      const { meeting } = event;
      dispatch({
        type: 'REFRESH_MEETING_STATE',
        meetingState: meeting,
        userId,
        reason: 'Web socket sent new meeting state',
      });
      setMeetingStateUpdated(true);
    }

    /*function onMeetingFull(data) {
      setIsMeetingFull(true);
    }*/

    /**
     * Dispatches an action to the appropriate reducer
     * based on the action type specified in the message object.
     */
    function onClientReduxAction(message) {
      dispatch(message);
    }

    const onEvent = (action) => {
      const key = action?.data?.key || action?.key;
      const eventHandler = eventHandlers[key];

      if (!eventHandler) {
        logwarn({
          message: `Tried handle unknown action. ${JSON.parse(action)}`,
        });
      }

      eventHandler(action, dispatch);
    };

    function onNotify(data) {
      const { type, message, params, notificationKey } = data;

      if (notificationKey === 'END_MEETING_MAX_TIME_REACHED') {
        mixpanel.track('Studio Time Limit Reached - Force Ending Session', {
          distinct_id: userId,
        });
      }

      if (notificationKey === 'JOIN_ATTEMPT_ON_FULL_MEETING') {
        return dispatchMeetingFullEnforcementToast();
      }

      if (notificationKey === 'MEETING_ENDING_SOON') {
        // TODO: Move the code to display these notifications based on notification keys to a different file.
        // TODO: Finalize Figma design.
        // TODO: Add 'Upgrade' button visible to the workspace billing owner only.
        // TODO: Add dynamic countdown if the number of remaining seconds < 15.
        let { secondsUntilEndOfMeeting } = params;

        // Subtract one from the second until end of meeting for display values.
        // The server sends notifications at T + 1 second to compensate for this.
        // This gives a nicer effect on the countdown timer when the meeting ends.
        secondsUntilEndOfMeeting = Math.floor(secondsUntilEndOfMeeting - 1);
        const minsUntilEndOfMeeting = Math.ceil(secondsUntilEndOfMeeting / 60);

        // Meeting is about to end.
        if (secondsUntilEndOfMeeting <= 0) return;

        // Ensure that we aren't hitting the user with spammy notifications if they have upgraded their plan mid-meeting.
        if (minsUntilEndOfMeeting > 15) return;

        const showDynamicCountdown = secondsUntilEndOfMeeting <= 60;

        const toMmSs = (secondsUntilEndOfMeeting) => {
          return moment()
            .startOf('day')
            .seconds(secondsUntilEndOfMeeting)
            .format('mm:ss');
        };

        let timeToEnd;

        if (secondsUntilEndOfMeeting > 45) {
          timeToEnd = `${
            minsUntilEndOfMeeting === 1
              ? '1 minute'
              : toMmSs(secondsUntilEndOfMeeting) + ' minutes'
          }`;
        } else {
          timeToEnd = `${
            secondsUntilEndOfMeeting === 1
              ? '1 second'
              : toMmSs(secondsUntilEndOfMeeting) + ' seconds'
          }`;
        }

        return dispatchMeetingEndingSoonEnforcementToast({
          timeToEnd,
          showDynamicCountdown,
        });
      }

      if (message) {
        const defaultParams = {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          closeOnClick: true,
          type: type || toast.TYPE.DEFAULT,
        };

        return toast(message, {
          ...defaultParams,
        });
      }
    }

    /**
     * Set up Socket Listeners.
     */
    socketObject.on('meetingState', onMeetingState);
    //socketObject.on('meetingFull', onMeetingFull);
    socketObject.on('clientReduxAction', onClientReduxAction);
    socketObject.on('notify', onNotify);
    socketObject.on('event', onEvent);

    /**
     * Clean up socket listeners in useEffect cleanup function.
     */
    return () => {
      socketObject.off('meetingState', onMeetingState);
      //socketObject.off('meetingFull', onMeetingFull);
      socketObject.off('clientReduxAction', onClientReduxAction);
      socketObject.off('notify', onNotify);
      socketObject.off('event', onEvent);
    };
  }, [
    socketObject,
    dispatch,
    dispatchMeetingFullEnforcementToast,
    dispatchMeetingEndingSoonEnforcementToast,
    authorized,
    meetingId,
    userId,
  ]);

  // 4) Show the media settings modal if:
  //
  //   * Media settings hasn't been completed.
  //   * Daily is loaded.
  //      * A timer is set here to show the user if this is taking a long time.
  //   * Socket has sent meeting state.
  //   * The user isn't invisible (eg. zync recording bot).
  //   * The event is not a preview event.

  const {
    state: callState,
    join,
    destroy,
    startVoiceTranscription,
  } = useDailyControls({
    subscribeToTracksAutomatically: false,
  });
  const [mediaSettingsCompleted, setMediaSettingsCompleted] =
    useState(subscriberOnly);
  const [isTimedOutWaitingForDaily, setIsTimedOutWaitingForDaily] =
    useState(false);

  const [isTimedOutWaitingToJoin, setIsTimedOutWaitingToJoin] = useState(false);

  const joinTimeoutRef = useRef(null);
  const joinPriorityTimeoutRef = useRef(null);

  const [isWaitingForJoin, setIsWaitingForJoin] = useState(false);

  const [showConnectionIssueMessage, setShowConnectionIssueMessage] =
    useState(false);

  const isWaitingForDailyConnection =
    meetingStateUpdated && callState === CALL_STATE_INITIALIZING;

  const displayMediaSettings =
    meetingStateUpdated &&
    callState === CALL_STATE_READY &&
    !mediaSettingsCompleted &&
    !fromZyncBottomsup;

  useEffect(() => {
    if (!isWaitingForDailyConnection) {
      setIsTimedOutWaitingForDaily(false);
      return;
    }
    const dailyTimeoutTimer = setTimeout(() => {
      setIsTimedOutWaitingForDaily(true);
      mixpanel.track('Meeting - Slow Daily Load');
      logerror({
        userId,
        meetingId,
        message: `Spent >= ${
          MS_UNTIL_JOIN_CONSIDERED_SLOW / 1000
        }s waiting for the daily object.`,
      });
    }, MS_UNTIL_JOIN_CONSIDERED_SLOW);

    return () => clearTimeout(dailyTimeoutTimer);
  }, [isWaitingForDailyConnection, meetingId, userId]);

  useEffect(() => {
    if (!isWaitingForJoin || !running) {
      return;
    }

    joinTimeoutRef.current = setTimeout(() => {
      setIsTimedOutWaitingToJoin(true);
      mixpanel.track('Meeting - Slow Meeting Join');
      logerror({
        userId,
        meetingId,
        message: `Spent >= ${
          MS_UNTIL_JOIN_CONSIDERED_SLOW / 1000
        }s waiting for join the meeting.`,
      });
    }, MS_UNTIL_JOIN_CONSIDERED_SLOW);

    return () => clearTimeout(joinTimeoutRef.current);
  }, [isWaitingForJoin, meetingId, userId, running]);

  // 5) Start the meeting if the current user is a host or a moderator (not a guest)
  //
  //   * The meeting must be neither completed nor running.

  const isSoloEpisode = series.settings.episodeType === episodeTypes.solo;
  const [wentLiveState, setWentLiveState] = useState(false);

  const initialSceneIndex = isSoloEpisode
    ? 1
    : previewSceneId && autoLaunchConfig
    ? autoLaunchConfig.slides.findIndex(
        (slide) => slide.sceneId === previewSceneId
      )
    : undefined;

  useEffect(() => {
    if (
      !mediaSettingsCompleted ||
      !subscribed ||
      running ||
      completed ||
      (isSoloEpisode ? !isSoloEpisode : !isMeetingController) ||
      !meetingId
    )
      return;

    loginfo({ userId, meetingId, message: `Attempting to start the meeting.` });
    const startMeeting = async () => {
      // Fetch and cache the latest meeting series state
      const result = await fetchSeriesApi(meetingSeriesId);
      setSeries(result);

      await sendEvent(userId, meetingId, {
        type: 'START_MEETING',
        userId,
        isPreview,
        initialSceneIndex,
      });
    };
    startMeeting();
  }, [
    mediaSettingsCompleted,
    subscribed,
    running,
    completed,
    userId,
    meetingId,
    meetingSeriesId,
    isPreview,
    isMeetingController,
    initialSceneIndex,
    setSeries,
    isSoloEpisode,
  ]);

  useEffect(() => {
    if (wentLive === undefined) return;
    setWentLiveState(wentLive);
  }, [wentLive]);

  // 6) Join the meeting both on daily.co & via zync API.
  useEffect(() => {
    if (
      leftMeeting ||
      !running ||
      !mediaSettingsCompleted ||
      callState !== CALL_STATE_READY
    )
      return;
    const joinDailyRoom = async () => {
      await join(userId);
      if (isMeetingController) {
        startVoiceTranscription();
      }
    };
    loginfo({
      userId,
      meetingId,
      message: `userId: ${userId},  Attempting to join the daily room`,
    });

    joinDailyRoom();
  }, [
    userId,
    mediaSettingsCompleted,
    meetingId,
    leftMeeting,
    callState,
    running,
    join,
    startVoiceTranscription,
    workspaceId,
    isMeetingController,
  ]);

  useEffect(() => {
    if (joined) {
      clearTimeout(joinTimeoutRef.current);
      clearTimeout(joinPriorityTimeoutRef.current);
    }
    if (
      !mediaSettingsCompleted ||
      !subscribed ||
      leftMeeting ||
      forceKicked ||
      !running ||
      !authorized ||
      joined
    ) {
      return;
    }

    const joinMeeting = async (joinMeetingAction) => {
      loginfo({
        userId,
        meetingId,
        message: `Attempting to join the meeting.`,
      });

      // Refetch the meeting series to get the latest cached meeting series
      const result = await fetchSeriesApi(meetingSeriesId);
      setSeries(result);
      await sendEvent(userId, meetingId, {
        type: joinMeetingAction || 'JOIN_MEETING',
        userName: userName || 'Unknown',
        emailAddress: emailAddress,
        roles,
        mobileUser: isMobile,
        invisible,
        userTimestamp: Date.now(),
      });
    };

    if (isTimedOutWaitingToJoin) {
      joinPriorityTimeoutRef.current = setTimeout(() => {
        setNumberOfJoinRetries((prevState) => prevState + 1);
        loginfo({
          userId,
          meetingId,
          message: `Waiting to join timed out. Using JOIN_MEETING_PRIORITY action now... Retry #${
            numberOfJoinRetries + 1
          }`,
        });
        joinMeeting('JOIN_MEETING_PRIORITY');
      }, MS_UNTIL_JOIN_CONSIDERED_SLOW / 2);
    } else {
      joinMeeting();
    }
  }, [
    avatarUrl,
    mediaSettingsCompleted,
    subscribed,
    joined,
    leftMeeting,
    forceKicked,
    running,
    meetingId,
    meetingSeriesId,
    userId,
    userName,
    emailAddress,
    authorized,
    roles,
    invisible,
    isTimedOutWaitingToJoin,
    numberOfJoinRetries,
    setSeries,
  ]);

  useEffect(() => {
    if (numberOfJoinRetries === MAX_JOIN_RETRIES && !joined) {
      clearTimeout(joinPriorityTimeoutRef.current);
      setShowConnectionIssueMessage(true);
    }

    if (joined) {
      setShowConnectionIssueMessage(false);
      setIsTimedOutWaitingToJoin(false);
    }
  }, [joined, numberOfJoinRetries]);

  // 7) Make the fake users join in if this is a demo meeting

  const [fakeUsersJoined, setFakeUsersJoined] = useState(false);

  useEffect(() => {
    if (invisible) {
      setIsWaitingForJoin(true);
    }
  }, [invisible]);

  useEffect(() => {
    const joinMeeting = async (fakeUsers) => {
      fakeUsers.forEach(async (fakeUser) => {
        await sendEvent(fakeUser.userId, meetingId, {
          type: 'JOIN_MEETING',
          userName: fakeUser.userName,
          emailAddress: fakeUser.userId,
          roles: fakeUser.roles,
          mobileUser: false,
          isFake: true,
          fakeVideoStream: fakeUser.fakeVideoStream,
          avatarUrl: fakeUser.avatarUrl,
        });
      });
      setFakeUsersJoined(true);
    };
    if (
      joined &&
      meetingLeaderUserId &&
      fakeUsers.length > 0 &&
      !fakeUsersJoined &&
      !disableBots
    ) {
      joinMeeting(fakeUsers);
    }
  }, [
    joined,
    meetingId,
    fakeUsersJoined,
    fakeUsers,
    disableBots,
    meetingLeaderUserId,
  ]);

  // 8) Unload the page when leaving.
  useEffect(() => {
    const confirmLeaving = (e) => {
      if (isDev()) return;
      e.preventDefault();
      e.returnValue = 'You are about to leave the meeting';
    };
    window.addEventListener('beforeunload', confirmLeaving);
    return () => window.removeEventListener('beforeunload', confirmLeaving);
  }, []);

  useEffect(() => {
    const destroyDaily = () => {
      destroy();
      if (socketObject) {
        socketObject.disconnect();
      }
    };
    window.addEventListener('unload', destroyDaily);
    return () => window.removeEventListener('unload', destroyDaily);
  }, [destroy, socketObject]);

  // Misc Tracking
  useEffect(() => {
    if (displayMediaSettings) {
      mixpanel.track('Meeting - Start Media Settings');
    }
  }, [displayMediaSettings]);

  // If fromZyncBottomsUp is set to true.
  // Don't show media settings.
  useEffect(() => {
    if (fromZyncBottomsup) {
      setMediaSettingsCompleted(true);
    }
  }, []);

  /*if (isMeetingFull) {
    return (
      <MeetingFullErrorPage
        meetingUrl={`/e/${meetingSeriesId}`}
        meetingOwner={series?.owner}
      />
    );
  }*/

  if (isTimedOutWaitingToJoin && !joined) {
    return (
      <ZyncLoadingModal size={Modal.size.sm}>
        <div className="mt-4 border-purple rounded-lg bg-purple/5 border-solid border flex flex-col justify-center items-center gap-2 py-3 px-4">
          {showConnectionIssueMessage ? (
            <>
              <div className="text-blue-dark font-medium text-sm">
                It appears you are having some trouble connecting to the
                internet. Please check your network connectivity and click
                reload to try again.
              </div>
              <Button
                color={Button.colors.PURPLE}
                size={Button.sizes.FULL}
                onClick={() => window.location.reload(false)}
              >
                Reload
              </Button>
            </>
          ) : (
            <>
              <div className="text-blue-dark font-medium text-sm">
                Having trouble joining...
              </div>
              <div className="text-blue-gray text-sm flex gap-1">
                Retrying <RetryPhrase number={numberOfJoinRetries} />
              </div>
            </>
          )}
        </div>
      </ZyncLoadingModal>
    );
  }

  if (!series) {
    return (
      <>
        <FuturisticBackground />
        <StatusModal label="Powering up" series={series} />
      </>
    );
  }

  if (isMobile && (isMeetingController || isSpeaker)) {
    return (
      <ZyncErrorModal
        title="Hey, wait!"
        message="Please join studio from laptop / desktop."
        imgSrc={errorModeratorJoiningOnMobileSrc}
      />
    );
  }

  if (
    isMobile &&
    !isMeetingController &&
    !isSpeaker &&
    !joinMeetingAsNonModeratorOnMobile
  ) {
    return (
      <ZyncErrorModal
        title="Mobile device unsupported"
        message={
          <div className="flex flex-col gap-5 text-blue-gray text-sm text-center">
            <div>
              Looks like you are on a Mobile. Zync is an interactive event
              platform that is best experienced from a laptop / desktop.
            </div>
            <div>
              If you are joining from a mobile you will only watch a Live Stream
              of the event.
            </div>
          </div>
        }
      >
        <div className="mt-10 flex flex-col gap-9 items-center w-full">
          <img
            className="w-32"
            src={errorNonModeratorJoiningOnMobileSrc}
            alt=""
          />
          <div className="flex gap-2.5">
            <div className="w-36">
              <Button
                color={Button.colors.WHITE}
                size={Button.sizes.FULL}
                onClick={() => history.push(`/e/${meetingSeriesId}`)}
              >
                <div className="text-xs">Cancel</div>
              </Button>
            </div>
            <div className="w-36">
              <Button
                color={Button.colors.PURPLE}
                size={Button.sizes.FULL}
                onClick={() => setJoinMeetingAsNonModeratorOnMobile(true)}
              >
                <div className="text-xs">Join anyways</div>
              </Button>
            </div>
          </div>
        </div>
      </ZyncErrorModal>
    );
  }

  if (forceKicked || leftMeeting || completed || authorized === false) {
    window.stopLocalRecording &&
      window.stopLocalRecording({ workspaceId, meetingSeriesId, meetingId });

    console.warn({
      userId,
      meetingId,
      message: `Redirecting to series page of series ${meetingSeriesId}. Left Meetting: ${leftMeeting}. Completed: ${completed}. Authorized: ${authorized}`,
    });

    loginfo({
      userId,
      meetingId,
      message: `Redirecting to series page of series ${meetingSeriesId}. Left Meetting: ${leftMeeting}. Completed: ${completed}. Authorized: ${authorized}`,
    });

    destroy();

    /**
     * When the user is ejected from the meeting, the forceKicked property
     * on the user object needs to be reset to false. This is necessary
     * otherwise the user will be automatically kicked out of the meeting
     * when the try to re-join.
     *
     * REVOKE_EJECT resets this forceKicked property to false.
     */
    if (forceKicked && meetingId) {
      sendEvent(userId, meetingId, { type: 'REVOKE_EJECT', userId });
    }

    if (!leftMeeting) {
      console.warn(userId, 'left meeting with dispatch action');
      dispatch({ type: 'LEAVE_MEETING', userId });
    }

    const redirectLink =
      (templateKey === 'meeting_speaker_interview_1x1' ||
        templateKey === 'trial_episode_template') &&
      isMeetingController
        ? `/e/${meetingSeriesId}/details`
        : `/e/${meetingSeriesId}/join`;

    console.warn('redirected back to meeting home page');

    if (isSpeaker && !wentLiveState && isSoloEpisode) {
      const inviteUrl = getSpeakerInviteUrl(
        series.settings.eventPresenters[0]?.speaker ||
          series.settings.eventPresenters[0],
        meetingSeriesId,
        true
      );

      return <Redirect to={inviteUrl} />;
    }

    if (isSpeaker && !isPreview) {
      return (
        <Redirect
          to={`/e/${meetingSeriesId}/speakerExit?workspaceId=${workspaceId}`}
        />
      );
    }

    if (isSpeaker && isPreview) {
      const inviteUrl = getSpeakerInviteUrl(
        isSoloEpisode
          ? series.settings.eventPresenters[0]?.speaker ||
              series.settings.eventPresenters[0]
          : registeredUser,
        meetingSeriesId,
        true
      );

      return <Redirect to={inviteUrl} />;
    }

    return <Redirect to={redirectLink} />;
  }

  if (displayMediaSettings) {
    return (
      <>
        <NavigationConfirmation confirmationMessage="Are you sure you want to leave?" />
        <FuturisticBackground />
        <DailyMediaSettings
          onJoin={() => {
            // communicate joining attempt in order to run necessary timers
            setIsWaitingForJoin(true);

            setMediaSettingsCompleted(true);
          }}
          onCancel={() =>
            isSoloEpisode
              ? history.push(
                  getSpeakerInviteUrl(
                    series.settings.eventPresenters[0]?.speaker ||
                      series.settings.eventPresenters[0],
                    series.meetingSeriesId,
                    true
                  )
                )
              : dispatch({ type: 'LEAVE_MEETING', userId })
          }
          subscriberOnly={!!subscriberOnly}
          accentColor={workspace?.brandKit?.accentColor}
        />
        <ToastOutlet />
      </>
    );
  }

  if (mediaSettingsCompleted && !running && !isMeetingController) {
    return <MeetingWaitingScreen series={series} />;
  }

  if (mediaSettingsCompleted && joined && running && meetingConfig) {
    return (
      <ChunkedLocalRecordingContextProvider
        meetingSeriesId={meetingSeriesId}
        workspaceId={workspaceId}
        isSoloEpisode={isSoloEpisode}
      >
        <SubscriptionActionsContextProvider
          series={series}
          setSeries={setSeries}
          subscription={subscription}
          refreshSubscription={refreshSubscription}
        >
          <NavigationConfirmation confirmationMessage="Are you sure you want to leave?" />
          <ToastOutlet isMobile={+isMobile} />
          {isMobile ? (
            <LiveMeetingPlayer
              userId={userId}
              series={series}
              subscriberOnly={subscriberOnly}
            />
          ) : (
            <LiveMeeting
              userId={userId}
              series={series}
              subscriberOnly={subscriberOnly}
              isPreview={isPreview}
            />
          )}
        </SubscriptionActionsContextProvider>
      </ChunkedLocalRecordingContextProvider>
    );
  }

  return (
    <>
      <FuturisticBackground />
      <StatusModal
        label={
          isTimedOutWaitingForDaily
            ? 'It is taking longer than expected to connect to media services. This may be due to slow connection speeds.'
            : 'Entering Studio'
        }
        series={series}
      />
    </>
  );
};

const fiveDotsLoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: fiveDotsLoading,
};

export const StatusModal = ({ label, series }) => {
  const { user, authenticated } = useSelector(
    (state) => state.auth,
    shallowEqual
  );
  const { userId } = user || {};
  const { meetingSeriesId } = series || {};

  const timeout = useRef(null);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      logerror({
        message: 'Status Modal showing for 10 seconds',
        userId,
        authenticated,
        meetingSeriesId,
      });
    }, 10_000);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [authenticated, meetingSeriesId, userId]);

  return (
    <ModalWindow
      bg="TRANSPARENT"
      size={Modal.size.md}
      zyncLogo={true}
      boxShadow="3xl"
    >
      <ModalBody>
        <div className="flex flex-col h-full w-full justify-center items-center">
          <div className="w-64">
            <Lottie options={fiveDotsLoadingOptions} />
          </div>
          <div className="text-blue-dark font-semibold text-xl ">{label}</div>
        </div>
      </ModalBody>
    </ModalWindow>
  );
};

export default Meeting;
