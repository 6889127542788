import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalTitle,
  ModalWindow,
} from '../../../components/Modal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from '../../../components/icons/CopyIcon';
import { TextAreaInput } from '../../../components/Input';
import { DownloadIcon } from '../../../components/icons/DownloadIcon';
import { CloudUploadIcon } from '../../../components/icons/CloudUploadIcon';
import classNames from '../../../helper/classNames';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { downloadGCSFile } from './PublishMenus';
import ReactPlayer from 'react-player';
import { RadioButton } from '../../../components/common/RadioButton';
import { Button } from '../../../components/common/Button';
import { PostTextAreaInput } from './PostTextAreaInput';
import { delay } from '../../../helper/exponentialFetchRetry';
import { useTrackActivity } from '../../../hooks/track';

const VISIBILITES = ['Public', 'Private', 'Unlisted'];

export const UploadToYoutubeModal = ({
  highlightId,
  createdByProcessorId,
  handlePublish,
  title,
  thumbnailTitle,
  description = '',
  setShowPublishModal,
  thumbnailUrl,
  uploadedToYoutube,
  playbackUrl,
  alternateVideoUrl,
  aspectRatio,
  posterUrl,
  canUploadToYoutube,
  saveHighlightSocialMediaPost,
  saveShortsSocialMediaPost,
  videoDownloadUrl,
  videoGCSUrl,
  shared,
  postTitle,
  uploadIsTierLimited,
}) => {
  const [editedDescription, setEditedDescription] = useState(description || '');
  const [isSavingPostTitleLoading, setIsSavingPostTitleLoading] =
    useState(false);
  const [titleInput, setTitleInput] = useState(postTitle || title);
  const [visibility, setVisibility] = useState('Public');
  const [isLoading, setIsLoading] = useState(false);
  const [errorPublishing, setErrorPublishing] = useState(false);
  const [checkErrorPublishing, setCheckErrorPublishing] = useState(false);
  const [isDownloadVideoLoading, setIsDownloadVideoLoading] = useState(false);
  const [errorDownloading, setErrorDownloading] = useState(null);

  const isDescriptionEdited = editedDescription.trim() !== description.trim();

  useEffect(() => {
    if (!checkErrorPublishing) return;
    if (!uploadedToYoutube) {
      setErrorPublishing(true);
      setIsLoading(false);
      setCheckErrorPublishing(false);
    } else {
      setShowPublishModal(false);
    }
  }, [checkErrorPublishing, setShowPublishModal, uploadedToYoutube]);

  const track = useTrackActivity();

  return (
    <ModalWindow
      size={Modal.size.xl}
      showCancel
      onCancel={() => {
        if (isLoading) return;
        setShowPublishModal(false);
      }}
    >
      <ModalTitle>{thumbnailTitle || title}</ModalTitle>
      <ModalBody>
        <div className="flex gap-[25px]">
          <div className="flex flex-col gap-1 shrink-0 flex-1">
            <div className="flex flex-col gap-2.5">
              <div className="flex justify-between">
                <span className="text-blue-dark text-sm font-medium">
                  Post title
                </span>
                <CopyToClipboard text={titleInput}>
                  <button>
                    <CopyIcon />
                  </button>
                </CopyToClipboard>
              </div>
              <div className="flex flex-col gap-1">
                <TextAreaInput
                  resizable={false}
                  onChange={(event, value) => {
                    setTitleInput(value);
                  }}
                  value={titleInput}
                  rows={3}
                  onBlur={() => {
                    track('Content Kit: Youtube Post Title Updated');
                  }}
                  maxLength={100}
                  error={titleInput.length > 100 || titleInput.length === 0}
                ></TextAreaInput>
                <div className="font-medium text-blue-gray text-sm self-end">
                  {titleInput.length}/100
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2.5">
              <div className="flex justify-between">
                <span className="text-blue-dark text-sm font-medium">
                  Description
                </span>
                <div className="flex gap-1">
                  {!shared && isDescriptionEdited && (
                    <Button
                      padding={Button.padding.NONE}
                      color={Button.colors.PURPLE}
                      border={Button.border.ROUNDED}
                      onClick={async () => {
                        track('Content Kit: Youtube Post Description Updated');
                        saveHighlightSocialMediaPost
                          ? await saveHighlightSocialMediaPost(
                              setIsSavingPostTitleLoading,
                              editedDescription,
                              highlightId,
                              'youtube'
                            )
                          : await saveShortsSocialMediaPost(
                              setIsSavingPostTitleLoading,
                              editedDescription,
                              highlightId,
                              'youtubeShortDescription'
                            );
                      }}
                      state={
                        isSavingPostTitleLoading
                          ? Button.states.LOADING
                          : Button.states.DEFAULT
                      }
                    >
                      <div className="text-xxs h-5 flex justify-center items-center px-2.5">
                        Save
                      </div>
                    </Button>
                  )}
                  <CopyToClipboard text={editedDescription}>
                    <button>
                      <CopyIcon />
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
              <PostTextAreaInput
                resizable={false}
                onChange={(event, value) => {
                  setEditedDescription(value);
                }}
                value={editedDescription}
                rows={aspectRatio === 'portrait' ? 8 : 12}
              ></PostTextAreaInput>
            </div>
            <div className="flex gap-2 mt-3">
              <span className="text-blue-dark text-sm font-medium">
                Audience
              </span>
              {VISIBILITES.map((v) => (
                <RadioButton
                  key={v}
                  value={v}
                  name="visibility"
                  checked={v === visibility}
                  onChange={(value) => {
                    setVisibility(v);
                  }}
                  label={v}
                />
              ))}
            </div>
          </div>
          <div
            className={classNames(
              'flex flex-col gap-[15px]',
              aspectRatio === 'portrait' ? 'w-1/4' : 'flex-1'
            )}
          >
            {errorPublishing && (
              <div className="font-medium text-red text-xs self-end">
                Error publishing to YouTube channel.
              </div>
            )}
            <div className="flex gap-[5px] justify-end">
              {videoDownloadUrl && (
                <a
                  onClick={() => {
                    track('Content Kit: Youtube Video Download Clicked');
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  className="bg-purple text-white text-xxs font-medium p-2 flex gap-[5px] rounded"
                  href={videoDownloadUrl}
                >
                  <DownloadIcon height={12} className="fill-white" />
                  Video
                </a>
              )}
              {videoGCSUrl && (
                <Button
                  padding={Button.padding.NONE}
                  color={Button.colors.PURPLE}
                  onClick={() => {
                    track('Content Kit: Youtube Video Download Clicked');
                    downloadGCSFile(
                      videoGCSUrl,
                      `${title}.mp4`,
                      setIsDownloadVideoLoading,
                      setErrorDownloading
                    );
                  }}
                  state={
                    isDownloadVideoLoading
                      ? Button.states.LOADING
                      : Button.states.DEFAULT
                  }
                >
                  <div className="bg-purple text-white text-xxs font-medium flex gap-[5px] rounded items-center h-min  px-2">
                    <DownloadIcon height={12} className="fill-white" />
                    Video
                  </div>
                </Button>
              )}
              {aspectRatio !== 'portrait' && posterUrl ? (
                <button
                  type="button"
                  className="bg-purple text-white text-xxs font-medium p-2 flex gap-[5px] rounded"
                  onClick={() => {
                    track('Content Kit: Youtube Poster Download Clicked');
                    downloadGCSFile(posterUrl, 'poster.png');
                  }}
                >
                  <DownloadIcon height={12} className="fill-white" />
                  Poster
                </button>
              ) : null}
              {thumbnailUrl && (
                <button
                  type="button"
                  className="bg-purple text-white text-xxs font-medium p-2 flex gap-[5px] rounded"
                  onClick={() => {
                    track('Content Kit: Youtube Thumbnail Download Clicked');
                    downloadGCSFile(thumbnailUrl, 'thumbnail.png');
                  }}
                >
                  <DownloadIcon height={12} className="fill-white" />
                  Thumbnail
                </button>
              )}
              {isLoading ? (
                <LoadingSpinner width="20px" />
              ) : (
                <>
                  {canUploadToYoutube && (
                    <div className="relative group">
                      <p
                        className={classNames(
                          'hidden rounded-lg bg-blue-dark text-white text-xs font-light absolute right-0 bottom-9 w-[280px] p-2 text-center',
                          uploadIsTierLimited && 'group-hover:block'
                        )}
                      >
                        Upgrade to Publish Directly to YouTube
                      </p>
                      <button
                        className={classNames(
                          'bg-[#FF0000] text-white text-xxs font-medium p-2 flex gap-[5px] rounded',
                          (uploadedToYoutube ||
                            titleInput.length > 100 ||
                            titleInput.length === 0 ||
                            uploadIsTierLimited) &&
                            'opacity-50'
                        )}
                        onClick={async () => {
                          setErrorPublishing(false);
                          setIsLoading(true);
                          track('Content Kit: Youtube Publish Clicked', {
                            groupId: highlightId,
                          });
                          await handlePublish(
                            highlightId,
                            createdByProcessorId,
                            titleInput,
                            editedDescription,
                            visibility.toLowerCase()
                          );
                          await delay(3_000);
                          setCheckErrorPublishing(true);
                        }}
                        disabled={
                          uploadedToYoutube ||
                          titleInput.length > 100 ||
                          titleInput.length === 0 ||
                          uploadIsTierLimited
                        }
                      >
                        <CloudUploadIcon />
                        Publish
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            {errorDownloading && (
              <div className="text-red text-xs font-light text-right">
                {errorDownloading}
              </div>
            )}
            {aspectRatio === 'portrait' ? (
              <style>{`.react-player > video { aspect-ratio: 9/16}`}</style>
            ) : null}
            <ReactPlayer
              className={classNames(
                'react-player',
                aspectRatio === 'portrait' ? 'aspect-[9/16]' : 'aspect-video'
              )}
              width={'100%'}
              height="auto"
              url={
                alternateVideoUrl.trim().length > 0
                  ? alternateVideoUrl
                  : playbackUrl
              }
              playing={!!thumbnailUrl}
              controls={true}
              light={thumbnailUrl}
              loop={false}
              volume={1}
              muted={false}
              pip={false}
              onPlay={() => {
                track('Content Kit: YouTube Video Played', {
                  groupId: highlightId,
                });
              }}
            />
          </div>
        </div>
      </ModalBody>
    </ModalWindow>
  );
};
